import React, { useState, useEffect } from "react";
import { SidebarData } from "./sidebarData";
import SubMenu from "./submenu";
import RuleModal from "../RuleModal";
import actions from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import style from "./sidestyle.css";
const Sidebar = () => {
  const [showModal, setShowModal] = useState(true);

  const handleModal = (value) => {
    setShowModal(value);
  };

  const navigate = useNavigate();
  useEffect(() => {}, []);
  const getHostname = () => {
    try {
      var host = window.location.hostname;
      const dots = host.split(".");
      return dots.at(-2).replace(/\d/g, "");
    } catch (ex) {}
  };
  return (
    <div style={{ position: "fixed", width: "260px" }}>
      <div className={`sidebar-container ${getHostname()}`}>
        <div className="SidebarWrap">
          {SidebarData().map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        </div>
      </div>
      {localStorage.getItem("ShowRules") === "true" && (
        <RuleModal showModal={showModal} handleModal={handleModal} />
      )}
    </div>
  );
};

export default Sidebar;

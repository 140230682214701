import React, { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const showToast = (message, type) => {
    const newToast = { message, type, id: Date.now() };
    setToasts((prevToasts) => [...prevToasts, newToast]);

    // Remove toast after a delay
    setTimeout(() => {
      removeToast(newToast.id);
    }, 2000);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div className="overlay-container" aria-live="polite" style={{ position: 'relative', zIndex: '1000' }}>
        <div id="toast-container" className="toast-top-right toast-container" style={{ position: 'fixed', top: '20px', right: '20px', zIndex: '1001' }}>
          {toasts.map((toast) => (
            <div key={toast.id} className={`ngx-toastr toast-${toast.type}`} style={{ position: 'relative' }}>
              <button type="button" aria-label="Close" className="toast-close-button" onClick={() => removeToast(toast.id)}>
                <span aria-hidden="true">×</span>
              </button>
              <div role="alert" className="toast-message" aria-label="requiredWhen validation failed">
                {toast.message}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ToastContext.Provider>
  );
};

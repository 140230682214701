import axios from 'axios';
import { LOGINAPI, LOGOUTAPI, CHECKISVALIDAPI } from './url';


class AuthServiceClass {
    async loginService(data) {
        const headers = {
            "Content-Type": "application/json",
        };
        return await axios.post(`${LOGINAPI}?ipAddress=${data?.ip}`, data?.data, {
            headers: headers,
        });
    }
    async logOutService() {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${LOGOUTAPI}`, {
            headers: headers,
        });
    }
    async CheckIsValidService() {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${CHECKISVALIDAPI}`, {
            headers: headers,
        });
    }
}


const AuthService = new AuthServiceClass();
export default AuthService;
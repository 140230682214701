import React from "react";
import Navbar from "../../../components/navbar";
import HomeFooter from "../../../components/HomeFooter";


const SignUp = () => {
  return (
    <div>
      <Navbar />
      <div className="main" style={{ backgroundImage: "url('/assets/images/login_bg-compress.jpg')" }}>
        <div className="container">
          <div className="row login signupForm align-items-center py-5 px-0">
            <div className="col-lg-5">
              <div className="whatsappBx">
                <p className="bxTitle"> Manual Book Deposit and Withdrawal </p>
                <div className="p-2">
                  <div className="row pt-4 text-start features align-items-center">
                    <div className="col-6 d-flex justify-content-center align-items-center">
                      <div className="ic"> <img src="./assets/icons/ic_level.svg" alt="" /> </div>
                      <h5 className="ps-2"> All Premium Websites </h5>
                    </div>
                    <div className="col-6 d-flex justify-content-center align-items-center">
                      <div className="ic"> <img src="./assets/icons/24-icon.svg" alt="" /> </div>
                      <h5 className="ps-2"> 24/7 Instant Withdrawal </h5>
                    </div>
                  </div>
                  {/* <div className="wh_btn"> <a className="btn" href=""> <i className="fa-solid fa-clock" style={{ marginRight: 5 }} />{" "} Coming Soon.... </a> </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="orTxt">
                <h5 >OR</h5>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="login_form p-0">
                <form noValidate="" className="ng-untouched ng-pristine ng-invalid">
                  <div className="text-center">
                    <p className="bxTitle mb-3 d-inline-block px-4"> Self Deposit and Self Withdrawal </p>
                  </div>
                  <div className="p-3">
                    <div className="form-field">
                      <div className="d-flex align-items-center"> <span className="ic"> <i className="fa-solid fa-user" /> </span> <input type="text" placeholder="Name" formcontrolname="name" className="form-control ng-untouched ng-pristine ng-invalid" /> </div> {/**/}
                    </div>
                    <div className="form-field">
                      <div className="d-flex align-items-center position-relative"> <span className="ic"> <i className="fa-solid fa-lock" /> </span> <input placeholder="Enter Password" formcontrolname="password" className="form-control ng-untouched ng-pristine ng-invalid" type="password" /> <button className="btn py-0 px-2 eye_ic"> <i className="fa-solid fa-eye ng-star-inserted" /> {/**/} {/**/} </button> </div> {/**/}
                    </div>
                    <div className="form-field">
                      <div className="position-relative d-flex align-items-center"> <span className="ic"> <i className="fa-solid fa-mobile-screen-button" /> </span>
                        <div className="d-flex w-100"> <select aria-label="select" formcontrolname="country_code" required="" className="form-select counrtyCode ng-untouched ng-pristine ng-valid">
                          <option value={91} className="ng-star-inserted"> {" "} (+91) India </option> {/**/}
                        </select> <input type="text" placeholder="Mobile Number" formcontrolname="mobile" className="form-control w-100 m_field ng-untouched ng-pristine ng-invalid" /> <button type="button" className="btnOtp"> OTP{" "} <i className="fa-solid fa-circle-right" /> </button> </div>
                      </div> {/**/} {/**/}
                    </div>
                    <div className="form-field">
                      <div className="d-flex align-items-center"> <span className="ic"> <img src="./assets/icons/ic_otp.png" alt="" style={{ width: 20 }} /> </span> <input type="text" formcontrolname="verify_otp" placeholder="OTP" className="form-control ng-untouched ng-pristine ng-invalid" /> </div> {/**/}
                    </div>
                    <div className="form-field d-flex align-items-center"> <span className="ic"> <i className="fa-solid fa-users" /> </span> <input type="text" formcontrolname="referred_code" placeholder="Referral Code" className="form-control ng-untouched ng-pristine ng-valid" /> </div>
                    <div className="form-field d-flex flex-column"> <label className="d-flex"> <input type="checkbox" name="ageValid" spellCheck="false" defaultValue="true" defaultChecked="" className="me-2" />{" "} I am over 18 years old.{" "} </label> </div>
                    <div className="form-field d-flex flex-column mb-2"> <button type="button" className="btn submit_btn"> Join Now {/**/} </button> </div>
                  </div>
                </form>
                <p className="text-center pb-4 pt-2"> Existing User?{" "} <a routerlink="/login" href="/login"> Click Here </a> </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="offerArea">
              <ul className="list-unstyled d-md-flex p-0 m-0">
                <li className="text-md-end"> <img src="./assets/img/offer-1.png" alt="" className="w-100" /> </li>
                <li > <img src="./assets/img/offer-2.png" alt="" className="w-100" /> </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default SignUp;

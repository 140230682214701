import { createSlice } from "@reduxjs/toolkit";

const marketSlice = createSlice({
    name: 'MarketData',
    initialState: {
        marketList: [],
        myMarketList: [],
        BetfareRets: [],
        eventDetailData: [],
        chipModalData: [],
        BetfareRetsBMAndFancyData: [],
        BetfareRetsMarketOdds: [],
        BetfareTossOddsData: [],
        newsData: "",
        matchBetModelList: [],
        fancyPostionData: [],
        profitLossOnRunnersData: [],
        allNewsData: [],
        fancyActiveList: [],
        CustomeBMList: [],
        setCurrentFPData: [],
        selectionData: [],
        useBookData: [],
        userBookName: "",
        eventBetLock: true,
        scoreData: [],
        tennisScoreData: []
    },
    reducers: {
        saveMarketDetails: (state, action) => {
            state.marketList = action.payload
        },
        saveMyMarketDetails: (state, action) => {
            state.myMarketList = action.payload
        },
        saveBetfareRets: (state, action) => {
            state.BetfareRets = action.payload
        },
        setEventDetail: (state, action) => {
            state.eventDetailData = action.payload
        },
        setChipModalDetails: (state, action) => {
            state.chipModalData = action.payload
        },
        saveNewsData: (state, action) => {
            state.newsData = action.payload
        },
        saveAllNewsData: (state, action) => {
            state.allNewsData = action.payload
        },
        saveBetfareRetsBMAndFancy: (state, action) => {
            state.BetfareRetsBMAndFancyData = action.payload
        },
        saveBfRMarketOdddsOther: (state, action) => {
            state.BetfareRetsMarketOdds = action.payload
        },
        saveBfRTossOddds: (state, action) => {
            state.BetfareTossOddsData = action.payload
        },
        setMatchBetData: (state, action) => {
            state.matchBetModelList = action.payload
        },
        setFancyPostionData: (state, action) => {
            state.fancyPostionData = action.payload
        },
        setProfitLossOnRunnersData: (state, action) => {
            state.profitLossOnRunnersData = action.payload
        },
        setFancyActiveList: (state, action) => {
            state.fancyActiveList = action.payload
        },
        setCustomeBMList: (state, action) => {
            state.CustomeBMList = action.payload
        },
        setCurrentFP: (state, action) => {
            state.setCurrentFPData = action.payload
        },
        setSelectionData: (state, action) => {
            state.selectionData = action.payload
        },
        setUseBookData: (state, action) => {
            state.useBookData = action.payload
        },
        setUserBookName: (state, action) => {
            state.userBookName = action.payload
        },
        setEventBetLock: (state, action) => {
            state.eventBetLock = action.payload
        },
        setScoreData: (state, action) => {
            state.scoreData = action.payload
        },
        setTennisScoreData: (state, action) => {
            state.tennisScoreData = action.payload
        }
    }
})

export const { setCustomeBMList, setTennisScoreData, setScoreData, setFancyActiveList, saveMarketDetails, saveBetfareRets, setEventDetail, saveMyMarketDetails, saveBetfareRetsBMAndFancy, saveBfRMarketOdddsOther, saveBfRTossOddds, setChipModalDetails, saveNewsData, saveAllNewsData, setMatchBetData, setFancyPostionData, setProfitLossOnRunnersData, setSelectionData, setCurrentFP, setUseBookData, setUserBookName, setEventBetLock } = marketSlice.actions;
export default marketSlice.reducer
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap'
import store from '../../redux/store';
import { setCurrentPositionModal } from '../../redux/slices/userSlice';
import * as FcIcons from 'react-icons/io5';
const FancyCurrentPostionModal = () => {


    const fancyBetList = useSelector((state) => state.marketSlice.setCurrentFPData?.Result);
    let selectionData = useSelector((state) => state.marketSlice.selectionData);
    let showModal = useSelector((state) => state.userSlice.showModal);

    const handleModal = (() => {
        store.dispatch(setCurrentPositionModal(false));
    });

    return (
        <Modal show={showModal} onHide={handleModal} size="sm">
            <Modal.Header >
                <Modal.Title>{selectionData?.RunnerName}</Modal.Title>
                <Button variant="secondary" onClick={handleModal}>
                    <FcIcons.IoClose />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                    <thead>
                        <tr role="row">
                            <th>Runs</th>
                            <th>Position</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fancyBetList?.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? "even hover-row" : "odd hover-row"}>
                                <td class="font-weight-bold" >{item?.RunValue}</td>
                                <td class="font-weight-bold" style={{ color: item.Pl < 0 ? 'red' : 'green' }}>{item?.Pl}</td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="warning" onClick={() => { handleModal(false); localStorage.setItem('ShowRules', false) }} style={{ fontSize: '13px' }}>
                    Accept
                </Button> */}
            </Modal.Footer>
        </Modal>
    )
}

export default FancyCurrentPostionModal

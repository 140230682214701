import React from 'react';

function ProgressIndicator() {
  return (
    <div className="progress-indicator">
      <div>Please wait</div>
      <div className="spinner"></div>
    </div>
  );
}

export default ProgressIndicator;

import { useState } from "react";
import { useParams } from "react-router-dom";

const LiveTV = () => {
  let { eventId } = useParams();
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  return (
    <>
      <div className="tag-container white-tag-container d-flex align-items-center">
        <span className="tag-text text-white">Live TV</span>
        <label className="toggle">
          <input type="checkbox" checked={isToggled} onChange={handleToggle} />
          <div className="slider"></div>
        </label>
      </div>
      {isToggled && (
        <iframe
          title="External Content"
          src={`https://shri999.com/tv/${eventId}`}
          width="100%"
          height="200px"
          style={{ background: "white" }}
          allowFullScreen
        ></iframe>
      )}
    </>
  );
};

export default LiveTV;

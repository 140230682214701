import React, { useState } from "react";
import actions from '../../redux/actions';

const ChangePassword = () => {
    const [data, setData] = useState({
        oldPassword: '',
        newPassword: '',
    })
    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value })
    }
    const changePassword = async () => {
        //if (data.newPassword === data.confirmPassword && data.confirmPassword.trim() !== '') {
        let res = await actions.changePasswordAction(data)
        console.log(res)
        if (!res?.data?.status) {
            alert(res?.data?.Result)
        }
        // }
        setData({
            oldPassword: '',
            newPassword: '',
        })
    }

    return (
        <div className="modal fade lg" id="changePassModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ marginTop: "50px" }}>
            <div className="modal-dialog" style={{ width: '395px' }}>
                <div className="modal-content">
                    <div className="modal-header plus-icon-modal-header">
                        <h4 className="modal-title">Change Password</h4>
                        <button type="button" className="close" data-bs-dismiss="modal" style={{ color: '#fff', opacity: 'inherit' }}>x</button>
                    </div>
                    <div className="modal-body pe-3 ps-3 pb-3 pt-2" style={{ border: '1px solid black' }}>
                        <div className="">
                            <label htmlFor="oldPassword" className="form-label edit-user-input-label mb-0">Old Password</label>
                            <input type="password" className="form-control report-details-input w-100" value={data.oldPassword} id="oldPassword" name="oldPassword" placeholder="Password" onChange={handleChange} />
                        </div>
                        <div className="">
                            <label htmlFor="newPassword" className="form-label edit-user-input-label mb-0">New Password</label>
                            <input type="password" className="form-control report-details-input w-100" value={data.newPassword} id="newPassword" name="newPassword" placeholder="Password" onChange={handleChange} />
                        </div>
                    </div>
                    <div className="modal-footer p-0">
                        <button type="button" className="btn btn-warning modal-btn" data-bs-dismiss="modal" style={{ fontSize: '13px' }} onClick={changePassword}>Change Password</button>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default ChangePassword;
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import store from "../../redux/store";
import { setUserBookName } from "../../redux/slices/marketSlice";
import { setSelectionData } from "../../redux/slices/marketSlice";
import { setCurrentPositionModal } from "../../redux/slices/userSlice";

const FancyBets = ({
  fancyprofitLossData,
  fancyOddsFareRate,
  handleShowBackPlaceBet,
  handleShowLayPlaceBet,
  marketID,
}) => {
  // const fancyPosition = useSelector((state) => state.marketSlice.fancyPostionData?.Result);  //fancy postion data
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Adjust the threshold as needed
  const [updatedFancyOddsFareRate, setUpdatedFancyOddsFareRate] = useState([]);
  const eventLock = useSelector((state) => state.marketSlice.eventBetLock);

  useEffect(() => {
    // Add a window resize event listener to toggle the marquee effect
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const excludedStrings = [
      "Total",
      "bhav",
      "by",
      ".1",
      ".2",
      ".3",
      ".4",
      ".5",
      ".6",
      "boundaries",
      "bahv",
    ];
    if (
      fancyprofitLossData &&
      fancyOddsFareRate &&
      fancyprofitLossData !== "No Data"
    ) {
      //debugger;
      const updatedFancyRate = fancyOddsFareRate?.map((runner) => {
        const matchingFancyData = fancyprofitLossData?.find(
          (item) => item.selectionId === runner.SelectionId
        );
        return matchingFancyData
          ? { ...runner, profitLoss: matchingFancyData.profitLoss }
          : runner;
      });

      const filteredData = updatedFancyRate.filter((item) => {
        return !excludedStrings.some((str) => item.RunnerName.includes(str));
      });
      setUpdatedFancyOddsFareRate(filteredData);
    } else {
      const filteredData = fancyOddsFareRate.filter((item) => {
        return !excludedStrings.some((str) => item.RunnerName.includes(str));
      });
      setUpdatedFancyOddsFareRate(filteredData);
    }
  }, [fancyprofitLossData, fancyOddsFareRate]);

  const bookHandler = (runner) => {
    store.dispatch(setSelectionData(runner));
    // setExpandedItem(null);
    actions.getFancyCurrent({
      marketId: marketID,
      selectionId: runner.SelectionId,
    });
    store.dispatch(setCurrentPositionModal(true));
  };

  return (
    <>
      <div className="tag-container white-tag-container">
        <div className="tag-text">Fancy</div>
      </div>
      <div className="table-responsive">
        <table className="table table-dark table-striped">
          <thead>
            <tr className="td-tr-botton-border">
              <th className="font-weight-bold" style={{ width: "50%" }}></th>
              <th style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="lay market-lay-back"
                  style={{ marginRight: "10px" }}
                >
                  <span className="font-weight-bold" style={{ color: "white" }}>
                    No
                  </span>
                </div>
                <div className="back market-lay-back">
                  <span className="font-weight-bold" style={{ color: "white" }}>
                    Yes
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {updatedFancyOddsFareRate?.map((runner) => {
              if (!eventLock) {
                runner.GameStatus = "SUSPENDED";
                runner.BackPrice1 = "-";
                runner.BackSize1 = "-";
                runner.LayPrice1 = "-";
                runner.LaySize1 = "-";
              }
              return (
                <tr key={runner.selectionId} className="td-tr-botton-border">
                  <td className="font-weight-bold">
                    <span
                      className={
                        !isMobile
                          ? ""
                          : runner.RunnerName.length < 25
                          ? ""
                          : "marquee-text"
                      }
                    >
                      {runner.RunnerName}
                    </span>
                    <br />

                    <a role="button" onClick={() => bookHandler(runner)}>
                      <span
                        style={{
                          color: runner.profitLoss < 0 ? "red" : "green",
                        }}
                      >
                        {runner.profitLoss}
                      </span>
                    </a>
                    <div className="position-relative ms-2 d-inline-block minmaxicon">
                      <a
                        href={"#" + runner.SelectionId}
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        className="bet-limit"
                        aria-controls={runner?.SelectionId}
                      >
                        <img src="/assets/images/esclamation_ic.svg" />
                      </a>
                      <div
                        className="fancy_info-popup collapse"
                        id={runner?.SelectionId}
                      >
                        <a
                          data-bs-toggle="collapse"
                          aria-expanded="false"
                          ria-controls="max_collapse"
                          className="close"
                          data-bs-target={"#" + runner?.SelectionId}
                        >
                          x
                        </a>
                        <dl>
                          <dt>Min / Max</dt>
                          <dd className="minMax">
                            {runner.min}-{runner.max}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </td>
                  <td
                    className={
                      runner?.GameStatus === "" ? "" : "suspended-event"
                    }
                    style={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "center",
                      "--dynamic-content": `"${runner?.GameStatus}"`,
                    }}
                  >
                    <div
                      className="rate lay market-lay market-lay-back"
                      style={{ marginRight: "10px" }}
                      onClick={() => handleShowLayPlaceBet(1, runner)}
                    >
                      <span className="font-weight-bold">
                        {runner?.LayPrice1 ? runner?.LayPrice1 : "0"}
                      </span>
                      <br />
                      <span>{runner?.LaySize1 ? runner?.LaySize1 : "0"}</span>
                    </div>
                    <div
                      className="rate back market-lay-back"
                      onClick={() => handleShowBackPlaceBet(1, runner)}
                    >
                      <span className="font-weight-bold">
                        {runner?.BackPrice1 ? runner?.BackPrice1 : "0"}
                      </span>
                      <br />
                      <span>{runner?.BackSize1 ? runner?.BackSize1 : "0"}</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FancyBets;

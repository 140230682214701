import React, { useEffect, useState } from 'react';
import ChipSettings from '../../screen/ChipSettings';
import { useSelector } from 'react-redux';

const PlaceBet = (props) => {

    const [isChecked, setIsChecked] = useState(false);
    const [totalStakeValue, setTotalStakeValue] = useState(0);
    const [profit, setProfit] = useState(0);
    const [loss, setLoss] = useState(0);
    const oddsPrice = props.oddsPrice;
    const marketType = props.marketType;
    const oddsSize = parseInt(props.oddsSize);


    const ChipValue = useSelector((state) => state.marketSlice.chipModalData?.Result);  //event details for market ids
    console.log("chipvalue", ChipValue);
    //const chipValuesArray = ChipValue ? ChipValue.ChipValue.split(',') : [];

    const chipValues = ChipValue?.ChipValue?.split(',');
    const chipNames = ChipValue?.ChipName?.split(',');


    const chipObjects = chipValues?.map((value, index) => ({
        ChipValue: value,
        ChipName: chipNames[index]
    }));


    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const handleSubmitClick = () => {
        props.handleSubmitPlaceBet(totalStakeValue)
    };

    const handleNumericButtonClick = (value) => {
        const newTotalStakeValue = totalStakeValue + parseInt(value, 10);
        setTotalStakeValue(newTotalStakeValue);
        //lay=1,back=0
        if (marketType === "FANCY") {
            if (props.typeOfPlaceBet === 1) {
                setProfit((oddsSize * newTotalStakeValue / 100).toFixed(2));
                setLoss(newTotalStakeValue);
            } else {
                setLoss((oddsSize * newTotalStakeValue / 100).toFixed(2));
                setProfit(newTotalStakeValue);
            }
        } else {
            //calculation of profit and loss stack place bet
       
             // var calculatedProfit = Math.round((oddsPrice * newTotalStakeValue) / 100);
             var calculatedProfit = Math.round((oddsPrice * newTotalStakeValue) - newTotalStakeValue);
             // var calculatedProfit = oddsPrice * newTotalStakeValue - 100;
            if (marketType === "Bookmaker")
                calculatedProfit = (oddsPrice * newTotalStakeValue) / 100;
            console.log(oddsPrice, calculatedProfit);
            if (props.typeOfPlaceBet === 0) {
                setProfit(calculatedProfit);
                setLoss(-newTotalStakeValue);
            } else {
                setProfit(newTotalStakeValue);
                setLoss(-calculatedProfit);
            }
        }

        const stakeInput = document.getElementById('stakeInputValue');
        if (stakeInput) {
            stakeInput.value = newTotalStakeValue;
        }
    };

    useEffect(() => {
        setTotalStakeValue(0)
        setProfit(0);
        setLoss(0);
    }, [props.typeOfPlaceBet]);


    return (
        <div className="place-bet-outer-container mt-3">
            <ChipSettings />
            <div className="place-bet-inner-container ">
                <div className={props.typeOfPlaceBet === 0 ? "place-bet-header p-2" : "place-bet-header-lay p-2"}>{props.runnerName}</div>
                <div className={props.typeOfPlaceBet === 0 ? "place-bet-content" : "place-bet-content-lay"}>
                    <div className="row col-12">
                        <div className="form-group  col col-md-3 col-lg-4 d-flex flex-column justify-content-center align-items-center">
                            <label htmlFor="stake" className='input-label flex-column'>ODDS</label>
                            <div className='d-flex'>
                                {/* <button className="btn btn-outline-secondary  btn-sm narrow-btn" type="button"> - </button> */}
                                <input type="text" className="form-control custom-bet-input text-center" readOnly value={oddsPrice} />
                                {/* <button className="btn btn-outline-secondary btn-sm narrow-btn" type="button"> + </button> */}
                            </div>
                        </div>
                        <div className="form-group col col-md-3 col-lg-4  d-flex flex-column  justify-content-center align-items-center p-0">
                            <label htmlFor="Stake" className='input-label'>STAKE</label>
                            <input type="text" id="stakeInputValue" className="form-control custom-bet-input" value={totalStakeValue} onChange={(e) => setTotalStakeValue(parseInt(e.target.value, 10) || 0)} />
                        </div>
                        <div className="col col-md-3 col-lg-4  d-flex align-items-center">
                            <button type="button" className="btn btn-primary btn-sm est_profile"> Est Profit : <span className="green">{profit}</span></button>
                        </div>
                    </div>

                    <div className="add_value col-12">
                        <ul className="numeric_key mt-2 mb-1">
                            {chipObjects?.map((data, index) => (
                                <li key={index} className="ng-star-inserted">
                                    <button type="button" className="btn stack-btn btn-sm" onClick={() => handleNumericButtonClick(data.ChipValue)}>
                                        +{data.ChipName}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="btn-container ms-auto d-flex justify-content-center">
                        <button type="button" className="btn cancel_btn me-2" onClick={() => { props.handleCancelClick() }}>Cancel Order</button>
                        {props.isLoading ?
                            <button className="btn place_bet_btn" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button> :
                            <button type="button" className="btn place_bet_btn" onClick={() => handleSubmitClick()}>
                                Place Bet
                                <p className="liability mb-0" >Liability: <span className="red">{loss}</span></p>
                            </button>
                        }

                    </div>

                    <div className="d-flex ">
                        <div className="col-3">
                            <span className="edit-stack" data-bs-toggle="modal" data-bs-target="#chipSettingsModal">Edit Stack</span>
                        </div>
                        <div className="col-9 d-flex align-items-center justify-content-end">
                            <label className="toggle-btn-label form-check-label me-2" style={{ fontSize: '12px' }} htmlFor="toggleSwitch">
                                Confirm bet before placing
                            </label>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="toggleSwitch"
                                    checked={isChecked}
                                    onChange={handleToggle}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PlaceBet;
import React, { useEffect } from "react";
import * as FaIcons from 'react-icons/fa';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import actions from '../../redux/actions';
import Sports from '../../components/sports/sports';
import { startConnection, stopConnection, subscribeToEvent } from "../../services/SignalRService";
import './style.css';

const Match = () => {

    let { sportId } = useParams()
    const Navigate = useNavigate()

    const marketList = useSelector((state) => state.marketSlice.marketList?.Result);
    const BetfareRets = useSelector((state) => state.marketSlice.BetfareRets?.Result);

    useEffect(() => {
        if (!marketList) {
            actions.getMarketList();
        }
        if (Array.isArray(marketList) && marketList.length > 0) { // Add a null check and check if marketList is an array
            const marketids = marketList.map(market => market?.MarketId);
            const queryString = marketids.join(',')
            // Initial API call when the component mounts
            actions.getBetfareRets(queryString);
            // Set up an interval to make subsequent API calls every 5 seconds
            const intervalId = setInterval(() => { actions.getBetfareRets(queryString) }, 5000);
            // Cleanup function to clear the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
    }, [marketList])
    


    const handleMatchSelect = (eventID, sportID, MarketName, SeriesName) => {
        Navigate(`/market/${sportId}/${eventID}`, { state: { seriesName: SeriesName, matchName: MarketName } });
    }

    useEffect(() => {
        startConnection();

        subscribeToEvent('UpdateMarketList', (data) => {
            actions.getMarketList();
        });

        // Don't forget to stop the connection when the component is unmounted
        return () => {
            stopConnection();
        };
    }, []);

    return (
        <NavbarSidebarWrapper>
            <div className="content-wrapper me-0" > {/* main content wrapper */} {/* Apply the custom border color */}
                <div className="select-match-header d-flex justify-content-between">
                    <span className=""><FaIcons.FaTrophy /> All</span>
                    <select className="form-control custom-select-box">
                        <option>All</option>
                    </select>
                </div>
                <Sports marketList={marketList} BetfareRets={BetfareRets} handleMatchSelect={handleMatchSelect} sportId={sportId} />
            </div>
        </NavbarSidebarWrapper>
    )
}

export default Match;
import React, { useEffect } from "react";
import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import actions from "../../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import { setSportNameById } from "../../utilities";
import { useSelector } from "react-redux";

const CasinoList = () => {
  const Navigate = useNavigate();

  const handleNavigate = (MarketName) => {
    Navigate(`/casino/${MarketName}`);
  };
  const data = [
    {
      icon: (
        <img className="casinoIcon" src="/assets/casino/horse-racing.png" />
      ),
      text: "horse-racing",
      Url: "horseracing",
    },
    {
      icon: <img className="casinoIcon" src="/assets/casino/7 up-down.png" />,
      text: "7 up-down",
      Url: "sevenupsevendown",
    },
    {
      icon: <img className="casinoIcon" src="/assets/casino/Roulette.png" />,
      text: "Roulette",
      Url: "roulette",
    },
    {
      icon: <img className="casinoIcon" src="/assets/casino/Andar-Bahar.png" />,
      text: "Andar Bahar",
      Url: "andarbahar",
    },

    {
      icon: <img className="casinoIcon" src="/assets/casino/Teen patti.png" />,
      text: "Teen patti",
      Url: "teenpatti",
    },
  ];
  return (
    <NavbarSidebarWrapper>
      <div className="content-wrapper me-0">
        <div className="d-flex flex-wrap">
          <div className="tag-container">
            <div className="tag-text">Casino Games</div>
          </div>
          <div className="row g-1">
            {data.map((item, index) => (
              <div
                className="col-6 col-sm-4  px-1 mb-2"
                onClick={() => handleNavigate(item.Url)}
              >
                <div className="gameCard" tabIndex={0}>
                  {item.icon}
                  <div className="gameName">
                    {item.Url}
                    {item.text}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </NavbarSidebarWrapper>
  );
};

export default CasinoList;

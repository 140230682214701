import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import actions from '../../redux/actions';
import { Modal, Button } from 'react-bootstrap'
import store from '../../redux/store';
import { setBetBookShowModal, setCurrentPositionModal } from '../../redux/slices/userSlice';
import * as FcIcons from 'react-icons/io5';

const BetBookModal = () => {
    
    const [currentPosition, setCurrentPosition] = useState([]);
    const [currentPosition1, setCurrentPosition1] = useState([]);
    
    const userBookList = useSelector((state) => state.marketSlice.useBookData);
    const teamName = useSelector((state) => state.marketSlice.userBookName);
    const showModal = useSelector((state) => state.userSlice.betBookShowModal);

    const parts = teamName.split(" v ");

    const getPL = (userName) => {
        const existArray = currentPosition1.filter((element) => element.userName === userName);
        return existArray;
    }

    const handleUsernameClick = (userPlData) => {
        let data = {
            marketId: userPlData?.MarketId,
            uid: encodeURIComponent(userPlData?.uid),
        }
        actions.getUserBook(data);
    }

    useEffect(() => {
        const userBookListResult = userBookList?.Result?.filter((element) => element.filterOrder === 1)
        const userBookListResult1 = userBookList?.Result?.filter((element) => element.filterOrder === 0)
        setCurrentPosition(userBookListResult)
        setCurrentPosition1(userBookListResult1)
    }, [userBookList]);


    const handleModal = (() => {
        store.dispatch(setBetBookShowModal(false));
    });

    return (
        <Modal show={showModal} onHide={handleModal} size="lg">
            <Modal.Header >
                <Modal.Title>Position Of {teamName}</Modal.Title>
                <Button variant="secondary" onClick={handleModal}>
                    <FcIcons.IoClose />
                </Button>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info">
                    <thead>
                        <tr role="row">
                            <th>User Name</th>
                            {parts.map((part, index) => (
                                <th key={index}>{part}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentPosition?.map((plData, index) => (
                            <tr key={index}>
                                <td>
                                    <span className="username" 
                                    // style={{ cursor: 'pointer' }} onClick={() => handleUsernameClick(plData)}
                                    >{plData.userName}</span>
                                </td>
                                {getPL(plData.userName).map((mkt, j) => (
                                    <td key={j} style={{ color: mkt.pL < 0 ? 'red' : 'green' }}>
                                        {mkt.pL.toFixed(2)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="warning" onClick={() => { handleModal(false); localStorage.setItem('ShowRules', false) }} style={{ fontSize: '13px' }}>
                    Accept
                </Button> */}
            </Modal.Footer>
        </Modal>
    )
}

export default BetBookModal

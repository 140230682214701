import { setCustomeBMList, setScoreData, setTennisScoreData, setFancyActiveList, saveMarketDetails, saveBetfareRets, setEventDetail, saveMyMarketDetails, saveBfRMarketOdddsOther, saveNewsData, saveAllNewsData, saveBetfareRetsBMAndFancy, saveBfRTossOddds, setChipModalDetails, setMatchBetData, setFancyPostionData, setProfitLossOnRunnersData, setCurrentFP, setUseBookData } from "../slices/marketSlice";
import MarketService from "../../services/marketService";
import store from "../store";
import { setLoggedIn } from '../../utilities';


export function getMarketList() {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketDetailsService().then((res) => {
            console.log("market res", res)
            store.dispatch(saveMarketDetails(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error getting form market", error)
            // rejact(error)
        })
    })
}
export function getMyMarketAction() {
    return new Promise((resolve, rejact) => {
        MarketService.getMyMarketService().then((res) => {
            ///   console.log("my market res", res)
            store.dispatch(saveMyMarketDetails(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error getting form my market", error)
            // rejact(error)
        })
    })
}
export function getBetfareRets(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareRetsService(marketids).then((res) => {
            //  console.log("getBetfareRets res", res)
            store.dispatch(saveBetfareRets(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error getting form getBetfareRets", error)
            // rejact(error)
        })
    })
}

export function getEventDetail(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.getEventDetailService(eventId).then((res) => {
            //  console.log("details resTest", res)
            store.dispatch(setEventDetail(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error event detials", error)
            // rejact(error)
        })
    })
}

export function getChipModal() {
    return new Promise((resolve, rejact) => {
        MarketService.getChipModalService().then((res) => {
            /// console.log("getChipModal", res)
            store.dispatch(setChipModalDetails(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            /// console.log("getChipModal", error)
            // rejact(error)
        })
    })
}

export function updateChipModal(data) {
    return new Promise((resolve, rejact) => {
        MarketService.updateChipModalService(data).then((res) => {
            console.log("updateChipModal", res)
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error event updateChipModal", error)
            // rejact(error)
        })
    })
}

export function GetNews() {
    return new Promise((resolve, rejact) => {
        MarketService.GetNewsService().then((res) => {
            ///  console.log("GetNews", res)
            store.dispatch(saveNewsData(res.data))
            resolve(res)
        }).catch((error) => {
            // if (error?.response?.status === 401) {
            //     setLoggedIn(0)
            //     window.location = '/login'
            // }
            //store.dispatch(saveNewsData(""))
            console.log("Error event GetNews", error)
            // rejact(error)
        })
    })
}

export function GetAllNews() {
    return new Promise((resolve, rejact) => {
        MarketService.GetAllNewsService().then((res) => {
            console.log("GetAllNews", res)
            store.dispatch(saveAllNewsData(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error event GetALLNews", error)
            // rejact(error)
        })
    })
}

export function getBetfareRetsBMAndFancy(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareRetsBMAndFancyService(marketids).then((res) => {
            store.dispatch(saveBetfareRetsBMAndFancy(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error getting form getBetfareRets", error)
            // rejact(error)
        })
    })
}


export function getBetfareRetsMarketAndOther(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareMarketAndOtherService(marketids).then((res) => {
            store.dispatch(saveBfRMarketOdddsOther(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error getting form getBetfareRets", error)
            // rejact(error)
        })
    })
}
export function getBetfareTossOdds(marketids) {
    return new Promise((resolve, rejact) => {
        MarketService.getBetfareTossOddsService(marketids).then((res) => {
            store.dispatch(saveBfRTossOddds(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error getting form getBetfareRets", error)
            // rejact(error)
        })
    })
}

export function postUserBetAPI(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postUserBetAPIService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error getting form getBetfareRets", error)
            // rejact(error)
        })
    })
}
export function postFancyBetAPI(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postFancyBetAPIService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                setLoggedIn(0)
                window.location = '/login'
            }
            console.log("Error getting form getBetfareRets", error)
            // rejact(error)
        })
    })
}
export function GetMatchedBetList(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetBetModelList(eventId).then((res) => {
            /// console.log("GetUserBetModelById", res)
            store.dispatch(setMatchBetData(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error event GetUserBetModelById bet", error)
            // rejact(error)
        })
    })
}
export function GetFancyPostionList(marketId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetFancyPostion(marketId).then((res) => {
            /// console.log("GetUserBetModelById", res)
            store.dispatch(setFancyPostionData(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error event GetUserBetModelById bet", error)
            // rejact(error)
        })
    })
}
export function GetProfitLossOnRunnersList(marketId, sportId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetProfitLossOnRunners(marketId, sportId).then((res) => {
            console.log("GetProfitLossOnRunners", res)
            store.dispatch(setProfitLossOnRunnersData(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error event GetProfitLossOnRunners bet", error)
            // rejact(error)
        })
    })
}

export function getactiveFancyList(MarketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getActiveFancyListService(MarketId).then((res) => {
            store.dispatch(setFancyActiveList(res.data.Result))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {

                window.location = '/login'
            }
            console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function getCustomeBMList(MarketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getCustomeBMService(MarketId + "B").then((res) => {
            store.dispatch(setCustomeBMList(res.data.Result))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {

                window.location = '/login'
            }
            console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function getFancyCurrent(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getFancyCurrentService(data).then((res) => {
            store.dispatch(setCurrentFP(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error", error)
        })
    })
}

export function getUserBook(bookData) {
    return new Promise((resolve, rejact) => {
        MarketService.getUserBookService(bookData).then((res) => {
            store.dispatch(setUseBookData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function GetScoreData(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.GetScoreDataService(eventId).then((res) => {
            store.dispatch(setScoreData(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error from get score data", error)
        })
    })
}

export function GetTennisScore(data) {
    return new Promise((resolve, rejact) => {
        MarketService.GetTennisScoreService(data).then((res) => {
            store.dispatch(setTennisScoreData(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error from get tennis score data", error)
        })
    })
}
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const NetworkErr = () => {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const statusCode = params.get('statusCode');

    const navigate = useNavigate()
    const onRetry = () => { navigate('/home') }
    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Error Code {statusCode}</h2>
            <h2 style={styles.title}>Network Error</h2>
            <p style={styles.message}>
                There was an issue connecting to the network. Please check your internet
                connection and try again.
            </p>
            <button style={styles.retryButton} onClick={onRetry}>
                Retry
            </button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        padding: '20px',
        color: 'white'
    },
    title: {
        fontSize: '24px',
        marginBottom: '10px',
    },
    message: {
        fontSize: '18px',
        marginBottom: '20px',
    },
    retryButton: {
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    },
};

export default NetworkErr;

import React, { useEffect, useState } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useToast } from '../../components/ToastContext';

const ChipSettings = () => {
    const location = useLocation();
    const { showToast } = useToast();
    const jsonData = useSelector((state) => state.marketSlice?.chipModalData?.Result);
    const [ChipData, setChipData] = useState([]);
    useEffect(() => {

        if (location.pathname !== '/' && location.pathname !== '/login') {
            if (!jsonData) {
                actions.getChipModal();
            }
            else {

                const chipValues = jsonData?.ChipValue?.split(',');
                const chipNames = jsonData?.ChipName?.split(',');


                const chipObjects = chipValues.map((value, index) => ({
                    ChipValue: value,
                    ChipName: chipNames[index]
                }));
                ///console.log(chipObjects, "pppppppppppppppppppppp")

                setChipData(chipObjects)
            }
        }
    }, [jsonData, location.pathname])

    const handleChange = (newChipName, newChipValue, index, fieldType) => {
        setChipData((prevChipData) => {
            const updatedChipData = [...prevChipData];
            const updatedChip = { ...updatedChipData[index] };

            if (fieldType === 'ChipName') {
                updatedChip.ChipName = newChipName;
            } else if (fieldType === 'ChipValue') {
                updatedChip.ChipValue = newChipValue;
            }

            updatedChipData[index] = updatedChip;
            return updatedChipData;
        });
    };


    const handleUpdateChipSetting = async () => {
        const formattedData = {
            ChipName: ChipData.map(chip => chip.ChipName).join(','),
            ChipValue: ChipData.map(chip => chip.ChipValue).join(',')
        };
        console.log(formattedData)
        let res = await actions.updateChipModal(formattedData)
        if (res?.data?.Status) {
            showToast("Chip updated successfully !!", 'success');
            actions.getChipModal();
        }
    }


    return (
        <div className="modal fade xl" id="chipSettingsModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog" role="document">
                <div className="modal-content" >
                    <div className="modal-header plus-icon-modal-header" >
                        <h4 className="modal-title">Chip Settings</h4>
                        <button type="button" className="close" data-bs-dismiss="modal" style={{ color: '#fff', opacity: 'inherit' }}>x</button>
                    </div>
                    <div className="modal-body pe-3 ps-3 pb-3 pt-2" style={{ border: '1px solid black' }}>
                        {ChipData?.map((item, index) => (
                            <div className="d-flex flex-wrap" key={index}>
                                <div className="col-6">
                                    <div className="me-1">
                                        <label htmlFor={`chipName_${index}`} className="form-label edit-user-input-label mb-0">Chip Name</label>
                                        <input
                                            type="text"
                                            className="form-control report-details-input w-100"
                                            id={`chipName_${index}`}
                                            name={`chipName_${index}`}
                                            value={item?.ChipName}
                                            onChange={(e) => handleChange(e.target.value, item?.ChipValue, index, 'ChipName')}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="">
                                        <label htmlFor={`chipValue_${index}`} className="form-label edit-user-input-label mb-0">Chip Value</label>
                                        <input
                                            type="text"
                                            className="form-control report-details-input w-100"
                                            id={`chipValue_${index}`}
                                            name={`chipValue_${index}`}
                                            value={item?.ChipValue}
                                            onChange={(e) => handleChange(item?.ChipName, e.target.value, index, 'ChipValue')}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                    <div className="modal-footer p-0">
                        <button type="button" className="btn btn-warning modal-btn" data-bs-dismiss="modal" style={{ fontSize: '13px' }} onClick={handleUpdateChipSetting}>Update Chip Settings</button>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default ChipSettings;
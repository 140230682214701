import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
    name: 'reportSliceData',
    initialState: {
        accountStatementDetails: [],
        profitAndLossDetails: [],
        betHistoryDetails: [],
        accountBetHistoryDetails: []
    },
    reducers: {
        saveAccountStatement: (state, action) => {
            state.accountStatementDetails = action.payload
        },
        saveMatchBet: (state, action) => {
            state.matchBetDetails = action.payload
        },
        saveAccountBetHistory: (state, action) => {
            state.accountBetHistoryDetails = action.payload
        },
        saveProfitAndLoss: (state, action) => {
            state.profitAndLossDetails = action.payload
        },
        saveBetHistory: (state, action) => {
            state.betHistoryDetails = action.payload
        },
    }
})

export const { saveAccountStatement, saveProfitAndLoss, saveBetHistory, saveAccountBetHistory, saveMatchBet } = reportSlice.actions;
export default reportSlice.reducer
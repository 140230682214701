import React, { useState, useEffect, useRef } from "react";
import PlaceBet from "../../components/PlaceBet";
import Bookmaker from "../Bookmaker";
import LiveTV from "../LiveTV";
import AllBetList from "../../components/AllBetList/AllBetList";
import { useParams, useNavigate } from "react-router-dom";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import Navbar from "../../components/navbar";
import * as MdIcons from "react-icons/md";
import FancyBets from "../FancyBets";
import { useToast } from "../../components/ToastContext";
import ProgressIndicator from "../../components/ProgressIndicator";
//import FooterMenu from '../../components/FooterMenu';
import wBetSound from "./raw/wrong.mp3";
import {
  setCustomeBMList,
  setEventBetLock,
  setFancyActiveList,
} from "../../redux/slices/marketSlice";
import sBetSound from "./raw/yipee.mp3";
import { isBetSound } from "../../utilities";
import {
  startConnection,
  stopConnection,
  subscribeToEvent,
} from "../../services/SignalRService";
import "./style.css";
import store from "../../redux/store";
import FancyCurrentPostionModal from "../../components/Modal/FancyCurrentPostionModal";
import BetBookModal from "../../components/Modal/BetBookModal";
const Market = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [isPlacingBet, setIsPlacingBet] = useState(false);
  const [showPlaceBet, setShowPlaceBet] = useState(false);
  const [isLoadingBet, setIsLoadingBet] = useState(false);
  const [typeOfPlaceBet, setTypeOfPlaceBet] = useState(null);
  const [selectType, setSelectType] = useState(null);
  const [oddsPrice, setOddsPrice] = useState(0);
  const [oddsSize, setOddsSize] = useState(null);
  const [marketType, setmarketType] = useState(null);
  const [marketID, setmarketID] = useState(null);
  const [runnerName, setRunnerName] = useState(null);

  const [data, setData] = useState({
    Exposure: 1,
    ReturnBalance: 0,
    UserId: 0,
    BetStatus: "Pending",
  });
  const { eventId, sportId } = useParams();
  const navigate = useNavigate();
  const timeoutRef = useRef(null);
  const { showToast } = useToast();

  const evmarketList = useSelector(
    (state) => state.marketSlice.eventDetailData.Result
  );
  const fancyOddsFareRate = useSelector(
    (state) => state.marketSlice.BetfareRetsBMAndFancyData?.fancy
  ); //fancy
  const fancyprofitLossData = useSelector(
    (state) => state.marketSlice.fancyPostionData?.Result
  );

  const activeFancyList = useSelector(
    (state) => state.marketSlice.fancyActiveList
  ); //fancy
  const eventDetail = evmarketList?.MarketList;
  const fancySetting = evmarketList?.fancy;

  var filteredMainlist = [];

  useEffect(() => {
    store.dispatch(setEventBetLock(fancySetting?.EvBetStatus));
  }, [fancySetting?.EvBetStatus]);

  if (fancySetting?.IsFancyActive) {
    if (fancyOddsFareRate)
      filteredMainlist = JSON.parse(JSON.stringify(fancyOddsFareRate));
    if (filteredMainlist) {
      filteredMainlist.forEach((item) => {
        item.min = fancySetting?.SessionMinStake;
        item.max = fancySetting?.SessionMaxStake;
      });
    }

    if (
      fancyOddsFareRate &&
      typeof fancyOddsFareRate[Symbol.iterator] === "function"
    ) {
      activeFancyList.forEach((activelistItem) => {
        // Check if SelectionId is not present in filteredMainlist
        const index = filteredMainlist.findIndex(
          (item) => item.SelectionId === activelistItem.SelectionId
        );

        if (
          !filteredMainlist.some(
            (item) => item.SelectionId === activelistItem.SelectionId
          )
        ) {
          // Push the entire activelistItem to filteredMainlist
          // filteredMainlist.push(activelistItem);
        } else {
          // Find the index of the matching item in filteredMainlist
          if (activelistItem.isCustome) {
            // Replace the values of the matching item in filteredMainlist
            filteredMainlist[index] = {
              ...filteredMainlist[index],
              LayPrice1: activelistItem.LayPrice1,
              LaySize1: activelistItem.LaySize1,
              BackPrice1: activelistItem.BackPrice1,
              BackSize1: activelistItem.BackSize1,
              GameStatus: activelistItem.GameStatus,
              max: activelistItem.max,
              min: activelistItem.min,
            };
          } else {
            if (!activelistItem.isActive) {
              const item = filteredMainlist.findIndex(
                (item) => item.SelectionId === activelistItem.SelectionId
              );
              filteredMainlist.splice(item, 1);
            } else {
              filteredMainlist[index] = {
                ...filteredMainlist[index],
                max: activelistItem.max,
                min: activelistItem.min,
              };
            }
          }
        }
      });
    } else {
      console.log("fancyOddsFareRate is not defined or not iterable.");
    }
    console.log("Active fancy true");
  } else {
    if (
      fancyOddsFareRate &&
      typeof fancyOddsFareRate[Symbol.iterator] === "function"
    ) {
      activeFancyList.forEach((activelistItem) => {
        // Check if SelectionId is not present in filteredMainlist

        if (
          !fancyOddsFareRate.some(
            (item) =>
              item.SelectionId === activelistItem.SelectionId &&
              activelistItem.isCustome
          )
        ) {
          if (activelistItem.isCustome) {
            filteredMainlist.push(activelistItem);
          }
        }

        if (activelistItem.isActive) {
          const item = fancyOddsFareRate.find(
            (item) => item.SelectionId === activelistItem.SelectionId
          );
          if (item) {
            Object.freeze(item);
            const objCopy = { ...item }; // 👈️ create copy
            objCopy.max = activelistItem.max;
            objCopy.min = activelistItem.min;
            filteredMainlist.push(objCopy);
          }
        }
        // Find the index of the matching item in filteredMainlist
        if (activelistItem.isCustome) {
          const index = filteredMainlist.findIndex(
            (item) => item.SelectionId === activelistItem.SelectionId
          );
          // Replace the values of the matching item in filteredMainlist
          filteredMainlist[index] = {
            ...filteredMainlist[index],
            LayPrice1: activelistItem.LayPrice1,
            LaySize1: activelistItem.LaySize1,
            BackPrice1: activelistItem.BackPrice1,
            BackSize1: activelistItem.BackSize1,
            GameStatus: activelistItem.GameStatus,
            max: activelistItem.max,
            min: activelistItem.min,
          };
        }
      });

      console.log("Active fancy false");
    } else {
      console.log("fancyOddsFareRate is not defined or not iterable.");
    }
  }
  useEffect(() => {
    startConnection();

    subscribeToEvent("GetActiveSession", (message) => {
      debugger;
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.Marketid === marketID) {
        store.dispatch(setFancyActiveList(data.fancyList));
      }
    });
    subscribeToEvent("GetActiveBM", (message) => {
      debugger;
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.Marketid === marketID + "B") {
        store.dispatch(setCustomeBMList(data.BMList));
      }
    });
    subscribeToEvent("EventSuspended", (message) => {
      debugger;
      console.log("Event Suspended");
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.EventId === eventId) {
        actions.getEventDetail(eventId);
        // var eststus = true;
        // if (data.status == "False")
        //  eststus = false;
        //store.dispatch(setEventBetLock(eststus));
      }
    });
    subscribeToEvent("updatenews", (message) => {});

    subscribeToEvent("BetPlacedMessage", (message) => {
      debugger;
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      debugger;
      if (data.EventId === eventId) {
        var marketid = data.MarketId;
        var mktype = data.MarketType;
        var BMID = marketid.replace("B", "");
        if (mktype === "FANCY") {
          actions.GetFancyPostionList(BMID);
          // call getFancyPosition api
        } else {
          //call getProfitLossOnRunners pass marketid
          actions.GetProfitLossOnRunnersList(BMID, sportId);
        }
        // call bet list api
        actions.GetMatchedBetList(data.EventId);
      }
    });
    console.log("1111111111".marketID);
    return () => {
      stopConnection();
    };
  }, [marketID, eventId]);

  useEffect(() => {
    // Fetch data and set marketID based on eventDetail
    if (eventDetail && eventDetail.length > 0 && eventDetail !== undefined) {
      var bm = eventDetail.find((obj) => obj.MarketType === "Bookmaker");
      if (sportId === "4") {
        if (bm !== undefined) {
          var MID = bm.MarketId.replace("B", "");
          setmarketID(MID);
          actions.getactiveFancyList(MID);
          actions.getCustomeBMList(MID);
          actions.GetFancyPostionList(MID);
        } else {
          var MID = eventDetail[0].MarketId;
          setmarketID(MID);
          actions.getactiveFancyList(MID);
          actions.getCustomeBMList(MID);
          actions.GetFancyPostionList(MID);
        }
      }
    }
  }, [eventDetail]);

  useEffect(() => {
    actions.getactiveFancyList(marketID);
    actions.getCustomeBMList(marketID);
  }, [marketID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (showPlaceBet) {
      timeoutRef.current = setTimeout(() => {
        setShowPlaceBet(false);
      }, 10000); // Hide after 10 seconds
    }
    return () => clearTimeout(timeoutRef.current); // Clean up the timer when re-rendering
  }, [showPlaceBet]);

  /// handle Show PlaceBet Box When hide
  const handleShowPlaceBet = (
    placeBetType,
    backOddsPrice,
    marketType,
    backOddsSize
  ) => {
    setTypeOfPlaceBet(placeBetType);
    if (oddsPrice !== undefined) {
      setShowPlaceBet(true);
      timeoutRef.current = setTimeout(() => {
        setShowPlaceBet(false);
      }, 10000);
    } else {
      setShowPlaceBet(false);
    }
  };

  const handleShowBackPlaceBet = (selectBet, runner, market, runr) => {
    //   const matchRunner = market?.runners.find(runner2 => runner2?.selectionId.toString() === runner?.selectionId.toString());
    setSelectType(selectBet);
    setmarketType(market?.MarketType);
    setOddsPrice(runner?.ex.availableToBack[0]?.price);
    setRunnerName(runr.RunnerName);
    handleShowPlaceBet(
      0,
      runner?.ex.availableToBack[0]?.price,
      market?.MarketType,
      0
    );
    setData({
      ...data,
      sportsId: market.SportsId,
      SelectionName: runr.RunnerName,
      SelectionID: runr.id.toString(),
      Odds: runner?.ex.availableToBack[0]?.price,
      Price: 0,
      OldPrice: runner?.ex.availableToBack[0]?.price,
      MarketType: market?.MarketType,
      MarketId: market?.MarketId,
      EventId: eventId,
      BetType: "Back",
    });
  };

  const handleShowLayPlaceBet = (selectBet, runner, market, runr) => {
    // const matchRunner = market?.runners.find(runner2 => runner2?.selectionId.toString() === runner?.selectionId.toString());
    setmarketType(market?.MarketType);
    setSelectType(selectBet);
    setOddsPrice(runner?.ex.availableToLay[0]?.price);
    setRunnerName(runr.RunnerName);
    handleShowPlaceBet(
      1,
      runner?.ex.availableToLay[0]?.price,
      market?.MarketType,
      0
    );
    setData({
      ...data,
      sportsId: market.SportsId,
      SelectionName: runr.RunnerName,
      SelectionID: runr.id.toString(),
      Odds: runner?.ex.availableToLay[0]?.price,
      Price: 0,
      MarketType: market?.MarketType,
      MarketId: market?.MarketId,
      EventId: eventId,
      BetType: "Lay",
    });
  };

  const handleShowBackPlaceBetFancy = (selectBet, runner) => {
    setSelectType(selectBet);
    setmarketType("FANCY");
    setOddsSize(runner?.BackSize1);
    setOddsPrice(runner.BackPrice1);
    setRunnerName(runner.RunnerName);
    handleShowPlaceBet(0, runner.BackPrice1, "FANCY", runner?.BackSize1);
    setData({
      ...data,
      sportsId: sportId,
      SelectionName: runner.RunnerName,
      SelectionID: runner.SelectionId,
      Odds: runner.BackPrice1,
      OldStack: runner.BackPrice1,
      Price: runner.BackSize1,
      OldPrice: runner.BackSize1,
      MarketType: "FANCY",
      MarketId: eventDetail[0]?.MarketId,
      EventId: eventId,
      BetStatus: "Pending",
      BetType: "Yes",
    });
  };

  const handleShowLayPlaceBetFancy = (selectBet, runner) => {
    setSelectType(selectBet);
    setmarketType("FANCY");
    setOddsSize(runner?.LaySize1);
    setOddsPrice(runner.LayPrice1);
    setRunnerName(runner.RunnerName);
    handleShowPlaceBet(1, runner?.LayPrice1, "FANCY", runner?.LaySize1);
    setData({
      ...data,
      sportsId: sportId,
      SelectionName: runner.RunnerName,
      SelectionID: runner.SelectionId,
      Odds: runner.LayPrice1,
      OldStack: runner.LayPrice1,
      Price: runner.LaySize1,
      OldPrice: runner.LaySize1,
      MarketType: "FANCY",
      MarketId: eventDetail[0]?.MarketId,
      EventId: eventId,
      BetStatus: "Pending",
      BetType: "No",
    });
  };

  // Delay between API calls in seconds
  const delayInSeconds = 2;

  // Function to fetch data from API and repeat it
  const fetchRetsBMAndFancyRepeat = async () => {
    if (eventDetail && eventDetail?.length > 0 && eventDetail !== undefined) {
      var bm = eventDetail.find((obj) => obj.MarketType === "Bookmaker");
      const marketids = eventDetail.map((market) =>
        market?.MarketId.replace(/[TB]+$/, "")
      );
      const uniqueMarketids = new Set(marketids);
      const queryString = [...uniqueMarketids].join(",");

      try {
        actions.getBetfareRetsMarketAndOther(queryString);
        if (bm !== undefined) {
          var BMID = bm.MarketId.replace("B", "");
          if (sportId === "4") {
            actions.getBetfareRetsBMAndFancy(BMID);
            actions.getBetfareTossOdds(BMID);
            //actions.GetFancyPostionList(BMID);
          }
        } else {
          var BMID = eventDetail[0].MarketId.replace("B", "");
          if (sportId === "4") {
            actions.getBetfareRetsBMAndFancy(BMID);
            actions.getBetfareTossOdds(BMID);
            // actions.GetFancyPostionList(BMID);
          }
        }
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  useEffect(() => {
    // Call the API immediately when the component mounts
    fetchRetsBMAndFancyRepeat();

    // Set up an interval to repeatedly call the API
    const intervalId = setInterval(() => {
      fetchRetsBMAndFancyRepeat();
    }, delayInSeconds * 1000); // Call the function every `delayInSeconds` seconds

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [eventDetail, sportId]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      actions.GetMatchedBetList(eventId);
    }, delayInSeconds * 30 * 1000); // Call the function every `delayInSeconds` seconds
    return () => {
      clearInterval(intervalId);
    };
  }, [eventId]);

  useEffect(() => {
    actions.getEventDetail(eventId);
    actions.getChipModal();
    actions.GetMatchedBetList(eventId);
  }, [eventId]);

  const handleCancelClick = () => {
    setShowPlaceBet(false);
  };

  const handleSubmitPlaceBet = async (totalstack) => {
    if (totalstack > 0) {
      setIsLoadingBet(true);
      setIsPlacingBet(true);
      const updatedData = {
        ...data,
        Stack: totalstack,
        OldStack: totalstack,
        Exposure: totalstack,
      };
      try {
        if (selectType === 0) {
          const response = await actions.postUserBetAPI(updatedData); // Add 'await' here
          showToast(
            response?.data?.Result,
            response?.data?.Status ? "success" : "error"
          );
          if (response?.data?.Status) {
            actions.GetUserBalanceAction();
            actions.GetMatchedBetList(eventId);
            actions.GetProfitLossOnRunnersList(updatedData.MarketId, sportId);
            actions.getEventDetail(eventId);
            if (isBetSound()) new Audio(sBetSound).play();
          } else {
            if (isBetSound()) new Audio(wBetSound).play();
          }
        } else {
          const response = await actions.postFancyBetAPI(updatedData);
          showToast(
            response?.data?.Result,
            response?.data?.Status ? "success" : "error"
          );
          debugger;
          if (response?.data?.Status) {
            actions.GetUserBalanceAction();
            actions.GetMatchedBetList(eventId);
            actions.GetFancyPostionList(updatedData.MarketId);
            actions.getEventDetail(eventId);
            if (isBetSound()) new Audio(sBetSound).play();
          } else {
            if (isBetSound()) new Audio(wBetSound).play();
          }
        }
      } catch (error) {
        console.log("Error:", error);
        if (isBetSound()) new Audio(wBetSound).play();
      } finally {
        setIsLoadingBet(false);
        setIsPlacingBet(false);
        setShowPlaceBet(false);
      }
    } else {
      // when totalstack is not greater than 0
      showToast("Total stack must be greater than 0", "error");
      if (isBetSound()) new Audio(wBetSound).play();
    }
  };

  return (
    <>
      <div className="container-fluid ">
        <Navbar hideToggle="true" />
        <div className="d-flex flex-wrap" style={{ marginTop: "100px" }}>
          <div className="col-12 col-md-8 scrollable-content">
            <div className="scrollable-content-inner">
              <button
                className="btn violet_button"
                onClick={() => navigate("/home")}
              >
                <MdIcons.MdArrowBackIos />
                Back
              </button>
              {isMobile && <LiveTV />} {/* Show LiveTV only in mobile view */}
              {eventDetail?.length && (
                <Bookmaker
                  eventDetail={eventDetail}
                  handleShowBackPlaceBet={handleShowBackPlaceBet}
                  handleShowLayPlaceBet={handleShowLayPlaceBet}
                />
              )}
              {sportId === "4" && (
                <FancyBets
                  fancyprofitLossData={fancyprofitLossData}
                  fancyOddsFareRate={filteredMainlist}
                  handleShowBackPlaceBet={handleShowBackPlaceBetFancy}
                  handleShowLayPlaceBet={handleShowLayPlaceBetFancy}
                  marketID={marketID}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="fixed-height-content">
              {!isMobile && <LiveTV />} {/* Show LiveTV only in desktop view */}
              {showPlaceBet && (
                <div className="PlaceBet">
                  <PlaceBet
                    isLoading={isLoadingBet}
                    oddsPrice={oddsPrice}
                    runnerName={runnerName}
                    marketType={marketType}
                    oddsSize={oddsSize}
                    typeOfPlaceBet={typeOfPlaceBet}
                    handleCancelClick={handleCancelClick}
                    handleSubmitPlaceBet={handleSubmitPlaceBet}
                  />
                </div>
              )}
              <AllBetList eventId={eventId} />
            </div>
            {isPlacingBet && isMobile && <ProgressIndicator />}
          </div>
        </div>
        {/* <FooterMenu /> */}
      </div>
      <FancyCurrentPostionModal />
      <BetBookModal />
    </>
  );
};

export default Market;

import AuthService from "../../services/authService";
import { setLoggedIn } from "../../utilities";

export const loginAction = (data) => {
  return new Promise((resolve, rejact) => {
    AuthService.loginService(data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        rejact(error);
      });
  });
};

export const logOutAction = () => {
  return new Promise((resolve, rejact) => {
    AuthService.logOutService()
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        rejact(error);
      });
  });
};

export const CheckIsValidAction = () => {
  return new Promise((resolve, rejact) => {
    //console.log("CheckIsValid data")
    AuthService.CheckIsValidService()
      .then((res) => {
        // console.log("CheckIsValid res", res)
        resolve(res);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setLoggedIn(0);
          window.location = "/login";
        } else {
          // if (error?.code === "ERR_NETWORK") {
          // window.location = '/network_err'
          window.location = `/network_err?statusCode=${error?.response?.status}`;
          // }
        }
        rejact(error);
      });
  });
};

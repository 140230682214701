import React, { useState, useEffect } from "react";
import NavbarSidebarWrapper from "../../../components/navbarSidebarWrapper";
import * as BsIcons from "react-icons/bs";
import actions from "../../../redux/actions";
import moment from "moment";
import { useSelector } from "react-redux";

const BetHistory = () => {
  const betHistoryList = useSelector(
    (state) => state.reportSlice?.betHistoryDetails?.Result
  );
  let totalrow = useSelector(
    (state) => state.reportSlice?.betHistoryDetails?.Count
  );
  let totalPage = Math.ceil(parseInt(totalrow) / 10);
  // const [pageNumber, setPageNumber] = useState()
  const [data, setData] = useState({
    userId: encodeURIComponent(
      JSON.parse(sessionStorage.getItem("loginUserDetails"))?.uid
    ),
    sportsId: 0,
    skipRec: 0,
    marketType: 0,
    betStatus: "Pending",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    let data = {
      userId: encodeURIComponent(
        JSON.parse(sessionStorage.getItem("loginUserDetails"))?.uid
      ),
      sportsId: 0,
      skipRec: 0,
      marketType: 0,
      betStatus: "Pending",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
    actions.getBetHistory(data);
  }, []);

  const handleChange = (e) => {
    let { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleResetData = (e) => {
    e.preventDefault();
    setData({
      ...data,
      sportsId: 0,
      marketType: 0,
      betStatus: "Pending",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    });
  };

  const callBetHistoryAction = (e) => {
    e.preventDefault();
    actions.getBetHistory(data);
  };

  const handlePageNoChange = (newPageNo) => {
    setData((prevState) => ({ ...prevState, skipRec: newPageNo }));
    actions.getBetHistory({ ...data, skipRec: newPageNo });
  };

  const DynamicPagination = (number) => {
    let divCount = number;
    if (number > 4) {
      divCount = 4;
    }

    const divs = [];
    for (let i = number - divCount + 1; i <= number; i++) {
      divs.push(
        <li key={i} class="page-item me-1">
          <button
            class={`${
              number === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setData((prevState) => ({ ...prevState, skipRec: i * 10 }));
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <NavbarSidebarWrapper>
      <div className="content-wrapper me-0">
        {" "}
        {/* main content wrapper */} {/* Apply the custom border color */}
        <div className="content-header d-flex justify-content-between">
          <span className="">Bet History</span>
        </div>
        <div className="row ">
          <div className="col-6 col-md-3 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Choose from Date
            </label>
            <input
              type="date"
              className="form-control custom-input"
              value={data?.startDate}
              name="startDate"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 col-md-3 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Choose to Date
            </label>
            <input
              type="date"
              className="form-control custom-input"
              value={data?.endDate}
              name="endDate"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 col-md-3 col-lg-2 mt-2">
            <label className="form-label white-input-label mb-0">
              Select Type
            </label>
            <select
              className="form-select custom-input"
              value={data?.betStatus}
              name="betStatus"
              onChange={handleChange}
            >
              <option value="Pending">Active</option>
              <option value="Settled">Settled</option>
            </select>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mt-2">
            <label className="form-label white-input-label mb-0">
              Select Sport
            </label>
            <select
              className="form-select custom-input"
              value={data?.sportsId}
              name="sportsId"
              onChange={handleChange}
            >
              <option value="0">Select</option>
              <option value="4">Cricket</option>
              <option value="1">Soccer</option>
              <option value="2">Tennis</option>
            </select>
          </div>
          <div className="col-6 col-md-3 col-lg-2 mt-2">
            <label className="form-label white-input-label mb-0">
              Bet Type
            </label>
            <select
              className="form-select custom-input"
              value={data?.marketType}
              name="marketType"
              onChange={handleChange}
            >
              <option value="0">Select</option>
              <option value="MATCH_ODDS">Match</option>
              <option value="FANCY">Session</option>
            </select>
          </div>
        </div>
        <div className="mt-1">
          <button
            type="button"
            className="btn btn-sm violet_button me-2"
            onClick={callBetHistoryAction}
          >
            Search
          </button>
          <button
            type="button"
            className="btn btn-sm grey_button"
            onClick={handleResetData}
          >
            <BsIcons.BsEraserFill /> Reset
          </button>
        </div>
        <div className="table-responsive mt-2">
          <div className="table-wrapper">
            <table className="table table-striped">
              <thead>
                <th>Placed</th>
                <th>Description</th>
                <th>Selection Type</th>
                <th>B/L</th>
                <th>Odds</th>
                <th>Stake</th>
                <th>BetStatus</th>
                <th>Exp/Profit</th>
              </thead>
              <tbody>
                {betHistoryList &&
                Array.isArray(betHistoryList) &&
                betHistoryList?.length > 0 ? (
                  betHistoryList?.map((item, index) => (
                    <tr key={index}>
                      <td className="dark-table-cell"> {item?.createdOn}</td>
                      <td className="dark-table-cell"> {item?.MatchName}</td>
                      <td className="dark-table-cell">
                        {" "}
                        {item?.SelectionName}
                      </td>
                      <td className="dark-table-cell"> {item?.BetType}</td>
                      <td className="dark-table-cell">
                        {" "}
                        {item?.MarketType === "FANCY"
                          ? item?.Odds + " (" + item?.Price + ")"
                          : item?.Odds}
                      </td>
                      <td className="dark-table-cell"> {item?.Stack}</td>
                      <td className="dark-table-cell"> {item?.BetStatus}</td>
                      <td className="dark-table-cell">
                        {" "}
                        <span className="red-text">{item?.Exposure}.00</span>
                        <span className="green-text">
                          /{item?.ReturnBalance}.00
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="8">
                      No Result
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {betHistoryList?.length > 0 && (
              <nav aria-label="Page navigation example" className="me-1">
                <ul class="pagination justify-content-start mb-1">
                  <li class="page-item me-1">
                    <button
                      class="page-link pagination-icon"
                      disabled={data?.skipRec === 0 ? true : false}
                      onClick={() => {
                        handlePageNoChange(data?.skipRec - 10);
                      }}
                    >
                      Previous
                    </button>
                  </li>
                  {DynamicPagination(data?.skipRec / 10 + 1)}
                  {totalPage === 1 ? (
                    ""
                  ) : (
                    <span style={{ color: "white" }}>...</span>
                  )}
                  {totalPage === 1 ? (
                    ""
                  ) : (
                    <li class="page-item me-1">
                      <button
                        class="page-link "
                        style={{
                          background: "#d5dfd9",
                          color: "#505050",
                          border: "2px solid #1f4e56",
                          padding: " 0px 7px 1px 7px",
                          fontWeight: "700",
                        }}
                      >
                        {totalPage}
                      </button>
                    </li>
                  )}

                  <li class="page-item">
                    <button
                      class="page-link pagination-icon"
                      disabled={
                        data?.skipRec / 10 + 1 === totalPage ? true : false
                      }
                      onClick={() => {
                        handlePageNoChange(data?.skipRec + 10);
                      }}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </NavbarSidebarWrapper>
  );
};

export default BetHistory;

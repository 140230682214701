import React, { useEffect } from "react";
import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import actions from "../../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import { setSportNameById } from "../../utilities";
import { useSelector } from "react-redux";

const Casino = () => {
  const Navigate = useNavigate();
  let { gameid } = useParams();

  const logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
  const uid = encodeURIComponent(logindata?.uid);
  return (
    <NavbarSidebarWrapper>
      <div className="content-wrapper me-0">
        <div className="d-flex flex-wrap">
          <iframe
            title="External Content"
            src={`https://cupidgamezone.com/${gameid}/?company=starbets9&token=${logindata.IsValidToken}`}
            width="100%"
            height="400px"
            style={{ background: "white" }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </NavbarSidebarWrapper>
  );
};

export default Casino;

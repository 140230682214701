import * as auth from './authActions';
import * as market from './marketActions';
import * as user from './userAction';
import * as report from './reportAction'

const actions = {
    ...auth,
    ...market,
    ...user,
    ...report
}

export default actions;
import React from "react";
import { formattedDateTime } from '../../utilities';
import { getImgBySportId } from '../ImgData/ImgData';

const Sports = ({ marketList, BetfareRets, handleMatchSelect, sportId, marketStatus }) => {

    return (
        <div className="table-responsive ">
            <div className="table-wrapper scrolling-table">
                <div className="table">
                    {marketList && Array.isArray(marketList) && marketList?.length > 0 && marketList?.filter(data => data?.SportsId?.toString() === sportId && (marketStatus ? data?.MarketStatus?.toString() === marketStatus : true))?.map((item, index) => {
                        const matchedBet = BetfareRets?.find(bet => bet.marketId === item.MarketId)

                        return (
                            <React.Fragment key={index}>
                                <div className="table-row" onClick={() => handleMatchSelect(item.EventId, sportId, item.MarketName, item.SeriesName)}>
                                    <div className="table-cell dark-table-cell">
                                        <div className="d-flex table-td">
                                            <div className="col-md-1 mt-md-2 ms-md-2 table-mobile-sport-section">
                                                <img src={getImgBySportId(item?.SportsId?.toString())?.img} alt={getImgBySportId(item?.SportsId?.toString())?.alt} width="20" height="20"></img><br />
                                                <img className="mt-1 table-desktop-star-section" src="/Assets/images/inactive-star.png" alt="star" width="20" height="20"></img>
                                            </div>
                                            <div className="col mt-2 ms-2 ms-md-0">
                                                <div>
                                                    <img className="mt-1" src="/Assets/images/clock.png" alt="clock" width="15" height="15"></img>
                                                    <span className="clock-time ms-1 mt-1">{formattedDateTime(item?.MarketTime)}</span>
                                                    {item?.MarketStatus === 1 && <span className="live-tag  ml-3">  In-Play</span>}
                                                </div>
                                                <span className="vs-team-name">{item?.MarketName}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-cell dark-table-cell">
                                        <div className="d-flex rates table-td">
                                            <div className="table-mobile-star-section"><img src="/Assets/images/inactive-star.png" alt="star" width="20" height="20"></img></div>
                                            <div className='rate lay'>{matchedBet?.runners?.[0]?.exchange?.availableToLay?.[0]?.price ?? '-'}</div>
                                            <div className='rate back'>{matchedBet?.runners?.[0]?.exchange?.availableToBack?.[0]?.price ?? '-'}</div>
                                            <div className='rate lay'>{matchedBet?.runners?.[2]?.exchange?.availableToLay?.[0]?.price ?? '-'}</div>
                                            <div className='rate back'>{matchedBet?.runners?.[2]?.exchange?.availableToBack?.[0]?.price ?? '-'}</div>
                                            <div className='rate lay'>{matchedBet?.runners?.[1]?.exchange?.availableToLay?.[0]?.price ?? '-'}</div>
                                            <div className='rate back'>{matchedBet?.runners?.[1]?.exchange?.availableToBack?.[0]?.price ?? '-'}</div>
                                        </div>
                                    </div>

                                </div>
                            </React.Fragment>
                        );
                    })}

                </div>
            </div>
        </div>
    )
}

export default Sports;
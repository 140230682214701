import React from "react";

const HomeFooter = () => {
  return (
    <div className="home_footer">
      <div className="full_wid">
        {" "}
        <img
          alt="img"
          src="https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/Gaming-Curacao-Logo.png"
        />{" "}
      </div>
      <div className="social_media_bar">
        <div
          className="inner"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          {" "}
          <a
            href="https://facebook.com/onebetz"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img
              alt="img"
              src="https://opt-v3-files.raksahb.com/static/mobile/brand/31/icon/facebookIcon.png"
            />{" "}
          </a>{" "}
          <a
            href="https://www.instagram.com/shyam__play/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img
              alt="img"
              src="https://opt-v3-files.raksahb.com/static/mobile/brand/31/icon/instagramIcon.png"
            />{" "}
          </a>{" "}
          <a
            href="https://plus.google.com/share?url=czxc"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <img
              alt="img"
              src="https://opt-v3-files.raksahb.com/static/mobile/brand/31/icon/youtubeIcon.png"
            />{" "}
          </a>{" "}
          <a href="https://t.me/shyamplay" target="_blank" rel="noreferrer">
            {" "}
            <img
              alt="img"
              src="https://opt-v3-files.raksahb.com/static/mobile/brand/31/icon/telegramIcon.png"
            />{" "}
          </a>{" "}
        </div>
      </div>
      <div className="gaming_partn">
        <div className="title"> Gaming Partners </div>
        <div className="logos">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/ezugiLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/aGLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/betGamesLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/evolutionGamesNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/betSoftLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/netentLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/tvbetLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/superSpadeLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/pragmaticplayCasinosNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/spinmaticLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/supernowaLogoNew.png"
          />{" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/BTILogoNew.png"
          />{" "}
        </div>
      </div>
      <div className="rules">
        <ul className="list-unstyled p-0 m-0 d-flex justify-content-center pt-3 pb-2 border-bottom">
          <li className="px-2">
            {" "}
            <span
              data-bs-toggle="modal"
              data-bs-target="#termsModal"
              className="text-decoration-none text-white"
            >
              {" "}
              Terms and Conditions{" "}
            </span>{" "}
          </li>
          <li className="px-2">
            {" "}
            <span
              data-bs-toggle="modal"
              data-bs-target="#privayModal"
              className="text-decoration-none text-white"
            >
              {" "}
              Privacy Policy{" "}
            </span>{" "}
          </li>
        </ul>
      </div>
      <div className="payment_meth">
        <div className="title"> Payment Methods </div>
        <div className="sc-nrwXf bnQDqA">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/internetBanking.png"
          />{" "}
        </div>
        <div className="sc-nrwXf bnQDqA">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/upiLogoNew.png"
          />{" "}
        </div>
        <div className="sc-nrwXf bnQDqA">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/gpayLogoNew.png"
          />{" "}
        </div>
        <div className="sc-nrwXf bnQDqA">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/paytmLogoNew.png"
          />{" "}
        </div>
        <div className="sc-nrwXf bnQDqA">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/astropayLogoNew.png"
          />{" "}
        </div>
      </div>
      <div className="sc-AnqlK jXpIDd">
        {" "}
        <a href="https://www.gamstop.co.uk/">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/gamestopLogo.png"
          />{" "}
        </a>{" "}
        <a href="https://www.begambleaware.org/">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/beAwareLogo.png"
          />{" "}
        </a>{" "}
        <a href="https://www.gamcare.org.uk/">
          {" "}
          <img
            alt="img"
            src="https://opt-v3-files.raksahb.com/static/media/gamcareLogo.png"
          />{" "}
        </a>{" "}
      </div>
    </div>
  );
};

export default HomeFooter;

import React, { useEffect, useState } from "react";
import style from './scorestyle.css';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import actions from "../../redux/actions";
const ScoreBoardTennis = () => {
    let html = [];
    const showScoreData = useSelector((state) => state.marketSlice.tennisScoreData?.score);

    let { eventId, sportId } = useParams();
    const [showScore, setShowScore] = useState([])

    useEffect(() => {
        if (showScoreData && Array.isArray(showScoreData) && showScoreData?.length > 0) {
            setShowScore(showScoreData[0])
        }
        else {
            setShowScore([])
        }
    }, [showScoreData])

    useEffect(() => {
        const fetchData = () => {
            actions.GetTennisScore({ eventId, sportId })
        };
        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [eventId, sportId])

    if (showScore?.eventTypeId === 2) {
        html.push(
            <div key="score_team" className="score_team">
                {showScore.score.home && (
                    <div className="sc">
                        {showScore.score.home.name}&nbsp;&nbsp;&nbsp;
                        {showScore.score.home.gameSequence && showScore.score.home.gameSequence.length > 0 && showScore.score.home.gameSequence.map((seq) => (
                            <span key={seq}>{seq}&nbsp;&nbsp;&nbsp;</span>
                        ))}
                        {`${showScore.score.home.games} - Points: ${showScore.score.home.score}`}
                    </div>
                )}

                {showScore.score.away && (
                    <div className="sc">
                        {showScore.score.away.name}&nbsp;&nbsp;&nbsp;
                        {showScore.score.away.gameSequence && showScore.score.away.gameSequence.length > 0 && showScore.score.away.gameSequence.map((seq) => (
                            <span key={seq}>{seq}&nbsp;&nbsp;&nbsp;</span>
                        ))}
                        {`${showScore.score.away.games} - Points: ${showScore.score.away.score}`}
                    </div>
                )}
            </div>
        );

        if (showScore?.currentSet) {
            html.push(<div key="current_set" className="current_set">Current Set: {showScore.currentSet}</div>);
        }
    } else if (showScore?.eventTypeId === 1) {
        html.push(
            <table key="runners-table" className="runners-table">
                <tbody>
                    <tr className="home-header">
                        <td className="home-runner">{showScore.score.home && showScore.score.home.name}</td>
                        <td className="home-score-container"><span className="ui-score-container">{showScore.score.home && showScore.score.home.score}</span></td>
                        <td className="match-time-container" rowSpan={4}>
                            <div className="match-time"><span>{`${showScore.score.away && showScore.score.away.score}′`}</span></div>
                        </td>
                    </tr>
                    <tr className="home-header">
                        <td className="home-runner">{showScore.score.away && showScore.score.away.name}</td>
                        <td className="home-score-container"><span className="ui-score-container">{showScore.score.away && showScore.score.away.score}</span></td>
                    </tr>
                </tbody>
            </table>
        );
    }
    else {
        html.push(<div key="current_set" className="current_set">---</div>)
    }

    return <div className="container-fluid"><div className="tag-container white-tag-container py-1 align-items-center" id="matchScore">{html}</div></div>;
};

export default ScoreBoardTennis;

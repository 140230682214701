import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./screen/Home";
import SignIn from "./screen/Auth/SignIn";
import SignUp from "./screen/Auth/SignUp";
import InPlay from "./screen/InPlay";
import Match from "./screen/Match";
import InfoBranding from "./screen/InfoBranding";
import AccountStatements from "./screen/Report/AccountStatements";
import ProfitAndLoss from "./screen/Report/Profit&Loss";
import BetHistory from "./screen/Report/BetHistory";
import Market from "./screen/Market";
import News from "./screen/News";
import NetworkErr from "./screen/NetworkErr";
import MyMarket from "./screen/MyMarket";
import ShowMatchBet from "./screen/Report/ShowMatchBet";
import BettingHistory from "./screen/Report/BettingHistory";
import CasinoList from "./screen/CasinoList/CasinoList";
import Casino from "./screen/Casino/Casino";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<InfoBranding />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/casinolist" element={<CasinoList />} />
        <Route path="/casino/:gameid" element={<Casino />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/inplay" element={<InPlay />} />
        <Route path="/match/:sportId" element={<Match />} />
        <Route path="/ac_statement" element={<AccountStatements />} />
        <Route path="/showmatchbet/:eventid" element={<ShowMatchBet />} />
        <Route
          path="/betting_history/:marketid/:userId"
          element={<BettingHistory />}
        />
        <Route path="/profit-loss" element={<ProfitAndLoss />} />
        <Route path="/bethistory" element={<BetHistory />} />
        <Route path="/market/:sportId/:eventId" element={<Market />} />
        <Route path="/mymarket" element={<MyMarket />} />
        <Route path="/news" element={<News />} />
        <Route path="/mymarket" element={<MyMarket />} />

        <Route path="/network_err" element={<NetworkErr />} />
        {/* Redirect to /home for any unknown routes */}
        {/* <Route path="*" element={<Navigate to="/" />} /> */}

        {/* Not Found route */}
        {/* <Route path="404" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
}

export default App;

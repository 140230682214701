import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { isBetSound, setBetSound, setLoggedIn } from "../../utilities";
import ChangePassword from "../../screen/changePassword";
import ChipSettings from "../../screen/ChipSettings";
import actions from "../../redux/actions";
import style from "./navstype.css";
const Navbar = (props) => {
  const news = useSelector((state) => state.marketSlice?.newsData?.Result);
  const userBalanceDetails = useSelector(
    (state) => state.userSlice?.userBalanceDetails?.Result
  );
  const navigate = useNavigate();

  useEffect(() => {
    document.title = getHostname();

    const checkTokenValidity = async () => {
      setTimeout(() => {
        console.log();
        actions.GetNews();
        actions.GetUserBalanceAction();
        checkTokenValidity();
      }, 5000);
      const logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
      const res = await actions.CheckIsValidAction();
      //  const resx = await actions.GetNews();
      // const resdx = await actions.GetUserBalanceAction();
      if (!res?.data?.Status) {
        sessionStorage.removeItem("loginUserDetails");
        navigate("/login");
      } else {
        var Token = res.data.Result;
        var loginToken = logindata.IsValidToken;
        if (loginToken != Token) {
          sessionStorage.removeItem("loginUserDetails");
          navigate("/login");
        }
      }
    };
    checkTokenValidity();
    // const intervalId = setInterval(
    //   checkTokenValidity, 5000
    //  );
    //return () => clearInterval(intervalId);
  }, []);

  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [sound, setSound] = useState(false);
  const getHostname = () => {
    try {
      var host = window.location.hostname;
      const dots = host.split(".");
      return dots.at(-2).replace(/\d/g, "");
    } catch (ex) {}
  };
  const handleWalletDropdown = () => {
    if (showUserDropdown) {
      setShowUserDropdown((prevState) => !prevState);
    }
    setShowWalletDropdown((prevState) => !prevState);
  };

  const handleUserDropdown = () => {
    if (showWalletDropdown) {
      setShowWalletDropdown((prevState) => !prevState);
    }
    setShowUserDropdown((prevState) => !prevState);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("loginUserDetails");
    setLoggedIn(0);
    navigate("/login");
  };

  const handleBetSound = () => {
    const betSoundEnabled = isBetSound();
    setSound(!betSoundEnabled);
    setBetSound(!betSoundEnabled);
    console.log("betSoundEnabled", betSoundEnabled);
  };

  return (
    <header
      className={`pk d-flex flex-column align-items-center position-fixed ${getHostname()} `}
    >
      <ChipSettings />
      <ChangePassword />
      <div className="container-fluid d-flex align--center py-1">
        <div onClick={props.onToggleSidebar} className="navbar-bar col-1">
          <FaIcons.FaBars style={{ width: "15px" }} />
        </div>
        <div className="d-flex align-items-center logo_area ps-2 ps-md-3 ps-lg-0">
          <a href="/home">
            <img
              src={`/assets/images/${getHostname()}.png`}
              alt="logo"
              className="w-100"
            ></img>
          </a>
        </div>
        <div className="right_area d-flex align-items-center order-lg-3 ms-auto ps-2">
          <ul>
            <li className="open">
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn loginBtn text-white f-14"
                  style={{ backgroundColor: "var(--bg-gray-200)" }}
                  onClick={handleWalletDropdown}
                >
                  W:{" "}
                  {userBalanceDetails?.MainBalance
                    ? parseFloat(userBalanceDetails?.MainBalance).toFixed(2)
                    : 0}{" "}
                  <span className=" fa fa-angle-down" />
                </button>
              </div>
              {showWalletDropdown && (
                <ul className="custom-dropdown">
                  <li>
                    <span
                      className="UserChipData"
                      onClick={handleWalletDropdown}
                    >
                      <i
                        className="fa fa-money-check-alt me-2"
                        aria-hidden="true"
                      />
                      Credit :{" "}
                      {userBalanceDetails?.CreditLimit
                        ? parseFloat(userBalanceDetails?.CreditLimit).toFixed(2)
                        : 0}
                    </span>
                  </li>
                  <li>
                    <Link to="/profit-loss" onClick={handleWalletDropdown}>
                      <i className="fa fa-book me-2" aria-hidden="true" />
                      P/L :{" "}
                      <span
                        className={`${
                          userBalanceDetails?.PL < 0 ? "red-text" : "green-text"
                        }`}
                      >
                        {" "}
                        {userBalanceDetails?.PL
                          ? parseFloat(userBalanceDetails?.PL).toFixed(2)
                          : 0}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <span className="endcooki" onClick={handleWalletDropdown}>
                      <i className="fa fa-book me-2" />L :{" "}
                      {userBalanceDetails?.Exposure
                        ? parseFloat(userBalanceDetails?.Exposure).toFixed(2)
                        : 0}
                    </span>
                  </li>

                  <li>
                    <span className="endcooki" onClick={handleWalletDropdown}>
                      <i className="fa fa-shield me-2" />
                      Balance :{" "}
                      {userBalanceDetails?.MainBalance
                        ? parseFloat(userBalanceDetails?.MainBalance).toFixed(2)
                        : 0}
                    </span>
                  </li>
                </ul>
              )}
            </li>
          </ul>
          <ul className="ps-0">
            <li className="open">
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn loginBtn text-white f-14"
                  onClick={handleUserDropdown}
                >
                  {
                    JSON.parse(sessionStorage.getItem("loginUserDetails"))
                      ?.UserName
                  }{" "}
                  <span className=" fa fa-angle-down" />
                </button>
              </div>
              {showUserDropdown && (
                <ul className="custom-dropdown">
                  <li>
                    <Link
                      to="/home"
                      className="UserChipData"
                      data-bs-toggle="modal"
                      data-bs-target="#chipSettingsModal"
                      onClick={handleUserDropdown}
                    >
                      <i
                        className="fa fa-pencil-square me-2"
                        aria-hidden="true"
                      />
                      Edit Stake
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/home"
                      data-bs-toggle="modal"
                      data-bs-target="#changePassModal"
                      onClick={handleUserDropdown}
                    >
                      <i className="fa fa-key me-2" aria-hidden="true" />
                      Change Password
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="endcooki"
                      to="https://wa.me/+12894817774"
                      target="_blank"
                      onClick={handleUserDropdown}
                    >
                      <img
                        className="me-2"
                        src="/assets/images/whatsapp_icon.png?v=12"
                        alt="support"
                        style={{ width: "17px" }}
                      />
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link className="endcooki" onClick={handleBetSound}>
                      <i
                        className={`fa ${
                          sound ? "fa-volume-up" : "fa-volume-off"
                        } me-2`}
                      />
                      {sound ? "Bet Sound On" : "Bet Sound Off"}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="endcooki"
                      to="/login"
                      onClick={handleLogout}
                    >
                      <i className="fa fa-sign-out me-2" />
                      Log Out
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="marquee_area d-flex align-items-center w-100">
        {" "}
        <span className="ic">
          {" "}
          <img src="./assets/img/speaker_blue.png" alt="" />{" "}
        </span>
        {/* <div className="scrolling-text">{news ? news[0]?.Message : ''}</div> */}
        <div className="scrolling-text">
          {news &&
            news.map((item, index) => (
              <span key={index}> * {item.Message}</span>
            ))}
        </div>
      </div>
    </header>
  );
};

export default Navbar;

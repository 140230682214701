import { config } from "../constants";

export const getApiUrl = (endpoint) => {
  return config.API_BASE_URL + endpoint;
};

//auth
export const LOGINAPI = getApiUrl("/api/Authenticate/ValidLogin");
export const LOGOUTAPI = getApiUrl("/api/Authenticate/logoutuser");
export const CHECKISVALIDAPI = getApiUrl("/api/Authenticate/checkIsValid ");
//market
export const GETMARKETLISTAPI = getApiUrl(
  "/api/MarketModels/GetHomeMarketList"
);
export const GETBETFARERETSAPI = getApiUrl("/api/MarketModels/GetBetfareRets");
export const GETEVENTDETAIL = getApiUrl("/api/MarketModels/GetEventDetail");
export const GETBETFAREBMFANCYAPI =
  "https://api.shriexchange.com/api/betfair/bmFancy"; //bookmaker and fancymarket
export const GETCHIPMODALAPI = getApiUrl("/api/ChipModels/GetChipModels");
export const UPDATECHIPMODALAPI = getApiUrl("/api/ChipModels/PutChipModel");
export const GETBETFAREMARKETOTHERAPI =
  "https://betfair.shriexchange.com/api/Odds/betfair"; //match_odds/other market
export const GETBETFARETOSSAPI = getApiUrl("/api/MarketModels/GetTossOdds"); //Only Toss Odds
export const GETMYMARKETAPI = getApiUrl("/api/MarketModels/GetMyMarket");

export const POSTFANCYBETAPI = getApiUrl(
  "/api/UserBetModels/PostFancyBetModelNew"
);
export const POSTUSERBETAPI = getApiUrl("/api/UserBetModels/PostUserBetModel");
export const GETNEWSAPI = getApiUrl("/api/NewsModels/GetNews");
export const GETALLNEWSAPI = getApiUrl("/api/NewsModels/GetNewsAll");
export const GETUSERBETMODELAPI = getApiUrl(
  "/api/UserBetModels/GetUserBetModelById"
); //Pass event ID
export const GETPROFITLOSSRUNNERAPI = getApiUrl(
  "/api/ProfitLossModels/getProfitLossOnRunners"
); //Pass market ID
export const GETFANCYPOSTIONAPI = getApiUrl(
  "/api/ProfitLossModels/getFancyPosition"
); //Pass market ID

export const GETACTIVEFANCYAPI = getApiUrl(
  "/api/CustomeMarket/GetCustomeFancy"
);
export const GETCUSTOMEBMAPI = getApiUrl("/api/CustomeMarket/GetCustomeMarket");
export const GETFANCYCURRENT = getApiUrl(
  "/api/UserBetModels/getFancyCurrentPositionClient"
);
export const GETUSERBOOK = getApiUrl("/api/MarketResultModels/GetUserBook"); //Bet Book

//user
export const CHANGEPASSWORDAPI = getApiUrl("/api/SignUpModels/changePwd");
export const GETUSERBALANCEAPI = getApiUrl(
  "/api/MarketResultModels/GetUserBalanceDetail"
);

export const GETSCOREAPI = "https://score.shriexchange.com/api/MatchOdds/Score"; //Get Score
export const GETTENNISSCORE =
  "https://score.shriexchange.com/api/MatchOdds/tennisscore"; //Get Score

//report
export const ACCOUNTSTATEMENTAPI = getApiUrl(
  "/api/Reports/GetAccountStatementModel"
);
//export const PROFITANDLOSSAPI = getApiUrl('/api/AccountStatementModels/GetSportsMarketStatementSpots');
export const PROFITANDLOSSAPI = getApiUrl("/api/Reports/GetProfitLossclient");
export const MATCHBETAPI = getApiUrl("/api/MarketResultModels/getOverAllPandL");
export const ACCOUNTBETHISTORYAPI = getApiUrl(
  "/api/UserBetModels/getMarketBetsOfUser"
);
export const ACCOUNTFANCYBETHISTORYAPI = getApiUrl(
  "/api/UserBetModels/getFancyBetsOfUser"
);
export const BETHISTORYAPI = getApiUrl("/api/UserBetModels/getBetHistory");

//SignalR hub
export const SIGNALR_HUB_URL = getApiUrl("/MessageHub");

import { combineReducers } from "redux";
import authSlice from '../slices/authSlice';
import marketSlice from "../slices/marketSlice";
import userSlice from "../slices/userSlice";
import reportSlice from "../slices/reportSlice";

const appReducer = combineReducers({
    authSlice,
    marketSlice,
    userSlice,
    reportSlice
})

export default appReducer
import * as signalR from "@microsoft/signalr";
import { SIGNALR_HUB_URL } from "./url";

let connection;

const configureConnection = () => {
  var uid =
    "?userId=" +
    encodeURIComponent(
      JSON.parse(sessionStorage.getItem("loginUserDetails"))?.uid
    );
  connection = new signalR.HubConnectionBuilder()
    .withUrl(SIGNALR_HUB_URL + uid)
    .withAutomaticReconnect([0, 500, 1000, 2000, 3000, 5000])
    // .skipNegotiation: true,
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection
    .start()
    .then(() => {
      console.log("SignalR Connected!");
    })
    .catch((error) => {
      console.log("SignalR Connection Error: ", error);
    });
};

const startConnection = () => {
  if (
    !connection ||
    connection.state === signalR.HubConnectionState.Disconnected
  ) {
    configureConnection();
  }
};

const stopConnection = () => {
  if (connection && connection.state === signalR.HubConnectionState.Connected) {
    connection.stop();
  }
};

const subscribeToEvent = (eventName, callback) => {
  if (connection) {
    connection.on(eventName, (data) => {
      console.log(data);
      callback(data);
    });
  }
};

const invokeHubMethod = (methodName, ...args) => {
  if (connection) {
    return connection.invoke(methodName, ...args);
  }
  return Promise.reject("Connection not initialized");
};

export { startConnection, stopConnection, subscribeToEvent, invokeHubMethod };

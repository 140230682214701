import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';


const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);
    const navigate = useNavigate();

    const showSubnav = () => {
        setSubnav((prevState) => !prevState)
    }

    // Initialize state variables for each submenu
    const [submenuStates, setSubmenuStates] = useState({});

    // Function to toggle the submenu state for a specific item
    const toggleSubmenu = (title) => {
        setSubmenuStates((prevState) => ({
            ...prevState,
            [title]: !prevState[title],
        }));
    };
    useEffect(() => { console.log(subnav, "opppp") }, [subnav])

    return (
        <>
            {item.tag?.trim() !== '' ? <div className='sidebar-tag'>{item.tag}</div> : <></>}
            <div className={`${item.subNav && subnav ? 'SidebarLink active-sidebar-link' : 'SidebarLink'}`}  >
                <Link className={`${item.subNav && subnav ? 'SidebarLink active-sidebar-link' : 'SidebarLink'}`} to={item.path} >
                    <span className='icon-container' ><span className={`${item.subNav && subnav ? 'sidebar-icon active-sidebar-icon' : 'sidebar-icon'}`}>{item.icon}</span></span>
                    <span className="SidebarLabel">{item.title}</span>
                </Link>
                <div className='sidebar-icon-indicator' style={{ backgroundColor: item.subNav && subnav ? 'var(--bg-primary)' : 'var(--gray-200)' }} onClick={showSubnav}>
                    <span style={{ fontSize: '14px' }}> {item.subNav && item?.subNav[0]?.marketNames && item?.subNav?.length}</span>
                    {item.subNav && subnav
                        ? item.iconOpened
                        : item.subNav
                            ? item.iconClosed
                            : null}
                </div>
            </div>
            {subnav &&
                item.subNav.map((item, index) => {
                    return (
                        <>
                            <div className="d-flex justify-content-between">
                                <Link to={item.path} key={index} className="DropdownLink " >
                                    <span className='dropdown-icon'>{item.icon}</span>
                                    <span className="SidebarLabel">{item.title}</span>
                                </Link>
                                {item?.marketNames && <div className='sidebar-icon-indicator mt-2' style={{ height: '26px', color: 'white', backgroundColor: item?.marketNames && submenuStates[item.title] ? 'var(--bg-primary)' : 'var(--gray-200)' }} onClick={() => toggleSubmenu(item.title)}>
                                    <span style={{ fontSize: '14px' }}>{item?.marketNames?.length}</span>
                                    {item?.marketNames && submenuStates[item.title]
                                        ? item.iconOpened
                                        : item?.marketNames
                                            ? item.iconClosed
                                            : null}
                                </div>
                                }
                            </div>
                            {submenuStates[item.title] &&
                                item?.marketNames?.map((item, index) => {
                                    return (
                                        <div className="d-flex justify-content-between" onClick={() => navigate(item.path)}>
                                            <Link to={item.path} key={index} className="NestedDropdownLink ms-3">
                                                <span className='dropdown-icon'>{item.icon}</span>
                                                <span className="SidebarLabel">{item.title}</span>
                                            </Link>
                                            <div className='sidebar-icon-indicator mt-2' style={{ height: '23px', fontSize: '14px', color: 'white', backgroundColor: 'var(--gray-200)' }} onClick={() => toggleSubmenu(item.title)}>
                                                {item.iconClosed}
                                            </div>
                                        </div>
                                    )
                                })}

                        </>
                    );
                })}
        </>
    );
};

export default SubMenu;

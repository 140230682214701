import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import FooterMenu from "./FooterMenu";

const NavbarSidebarWrapper = (props) => {
    const sidebarRef = useRef(null);
    const location = useLocation(); // Get the current location
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    function handleToggleSidebar() {
        let display = sidebarRef.current.style.display;
        if (display === "" || display === "none")
            sidebarRef.current.style.display = "inline-block";
        else sidebarRef.current.style.display = "none";
    }

    // Hide the sidebar if the current path is "/"
    const isHomePath = location.pathname === "/";

    const containerStyle = {
        padding: windowWidth <= 767 ? '0 0 70px 0' : '0 0 0 0',
      };

    return (
        <div className="container-fluid" style={containerStyle}>

            {/* navbar */}
            <Navbar onToggleSidebar={handleToggleSidebar} />

            <div className="row me-0">
                {/* sidebar */}
                <div
                    ref={sidebarRef}
                    className="sidebar-wrapper"
                    style={{ marginTop: isHomePath ? "0" : "100px", zIndex: '1000' }} // Adjust marginTop
                >
                    {isHomePath ? null : <Sidebar />}
                </div>

                {props.children}
            </div>

            <FooterMenu />
        </div>
    );
};

export default NavbarSidebarWrapper;

import axios from 'axios'
import { CHANGEPASSWORDAPI, GETUSERBALANCEAPI } from './url';

class UserServiceClass {

    async changePasswordService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${CHANGEPASSWORDAPI}?uid=${logindata?.uid}&oldPwd=${data?.oldPassword}&newPwd=${data?.newPassword}`, {
            headers: headers,
        });
    }

    async GetUserBalanceService() {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${GETUSERBALANCEAPI}`, {
            headers: headers,
        });
    }
}



const UserService = new UserServiceClass();

export default UserService;
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'userListData',
    initialState: {
        userBalanceDetails: {},
        showModal:false,
        betBookShowModal:false
    },
    reducers: {
        saveUserBalanceDetails: (state, action) => {
            state.userBalanceDetails = action.payload
        },
        setCurrentPositionModal: (state, action) => {
            state.showModal = action.payload
        },
        setBetBookShowModal: (state, action) => {
            state.betBookShowModal = action.payload
        },
    }
})

export const { saveUserBalanceDetails,setCurrentPositionModal,setBetBookShowModal } = userSlice.actions;
export default userSlice.reducer
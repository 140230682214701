import { useEffect } from "react";
import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import actions from "../../redux/actions";
import { formattedDateTime } from "../../utilities";
import { useSelector } from "react-redux";

const News = () => {
    const newsList = useSelector((state) => state.marketSlice?.allNewsData?.Result)

    useEffect(() => {
        actions.GetAllNews();
    }, [])

    return (
        <NavbarSidebarWrapper>
            <div className="content-wrapper me-0" >
                <div className="table-responsive mt-">
                    <div className="table-wrapper">
                        <table className="table  table-bordered">
                            <thead>
                                <th>Date</th>
                                <th>News</th>
                            </thead>
                            <tbody>
                                {newsList && Array.isArray(newsList) && newsList?.length > 0 ? newsList?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="dark-table-cell"> {formattedDateTime(item?.createdOn)}</td>
                                        <td className="dark-table-cell">{item?.Message} </td>
                                    </tr>
                                ))
                                    :
                                    <tr>
                                        <td className="text-center" colSpan='5'>No Result</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </NavbarSidebarWrapper>
    )
}

export default News;
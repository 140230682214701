import axios from 'axios'
import { ACCOUNTSTATEMENTAPI, BETHISTORYAPI, PROFITANDLOSSAPI, MATCHBETAPI, ACCOUNTBETHISTORYAPI, ACCOUNTFANCYBETHISTORYAPI } from './url';

class ReportServiceClass {

    async getAccountStatementService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${ACCOUNTSTATEMENTAPI}?uid=${encodeURIComponent(JSON.parse(sessionStorage.getItem("loginUserDetails"))?.uid)}&skipRec=${data?.skipRec}&type=${data?.type}&psize=${data?.psize}&startDate=${data?.startDate}&endDate=${data?.endDate}&sportsId=${data?.sportsId}`, {
            headers: headers,
        });
    }
    async getMatchBetService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${MATCHBETAPI}?marketId=${data.marketId}&role=${data.role}&uid=${data.userId}`, {
            headers: headers,
        });
    }
    async getAccountBetHistoryService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${ACCOUNTBETHISTORYAPI}?marketId=${data.marketId}&role=${data.role}&userId=${data.userId}`, {
            headers: headers,
        });
    }
    async getAccountFancyBetHistoryService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${ACCOUNTFANCYBETHISTORYAPI}?marketId=${data.marketId}&userId=${data.userId}`, {
            headers: headers,
        });
    }

    async getProfitAndLossService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${PROFITANDLOSSAPI}?skipRec=${data?.skipRec}&psize=${data?.psize}&startDate=${data?.startDate}&endDate=${data?.endDate}&sportsId=${data?.sportsId}`, {
            headers: headers,
        });
    }

    async getBetHistoryService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata.Token}`
        };

        return await axios.get(`${BETHISTORYAPI}?userId=${data?.userId}&skipRec=${data?.skipRec}&marketType=${data?.marketType}&betStatus=${data?.betStatus}&startDate=${data?.startDate}&endDate=${data?.endDate}&sportsId=${data?.sportsId}`, {
            headers: headers,
        });
    }
}



const ReportService = new ReportServiceClass();

export default ReportService;
import axios from 'axios'
import { GETACTIVEFANCYAPI, GETSCOREAPI, GETTENNISSCORE, GETCUSTOMEBMAPI, GETMARKETLISTAPI, GETBETFARERETSAPI, GETEVENTDETAIL, GETMYMARKETAPI, GETBETFAREBMFANCYAPI, GETBETFAREMARKETOTHERAPI, GETBETFARETOSSAPI, GETCHIPMODALAPI, UPDATECHIPMODALAPI, POSTUSERBETAPI, GETNEWSAPI, GETALLNEWSAPI, POSTFANCYBETAPI, GETUSERBETMODELAPI, GETFANCYPOSTIONAPI, GETPROFITLOSSRUNNERAPI, GETFANCYCURRENT, GETUSERBOOK } from './url'

class MarketServiceClass {
    async getMarketDetailsService() {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETMARKETLISTAPI}`, {
            headers: headers,
        });
    }
    async getMyMarketService() {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETMYMARKETAPI}`, {
            headers: headers,
        });
    }
    async getBetfareRetsService(marketids) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETBETFARERETSAPI}?marketids=${marketids}`, {
            headers: headers,
        });
    }
    async getEventDetailService(eventId) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETEVENTDETAIL}?eventId=${eventId}`, {
            headers: headers,
        });
    }
    async getBetfareRetsBMAndFancyService(marketids) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETBETFAREBMFANCYAPI}/${marketids}`, {
            headers: headers,
        });
    }
    async getBetfareMarketAndOtherService(marketids) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        //console.log(data)
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETBETFAREMARKETOTHERAPI}/${marketids}`, {
            headers: headers,
        });
    }
    async getBetfareTossOddsService(marketids) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        //console.log(data)
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETBETFARETOSSAPI}?marketids=${marketids}T`, {
            headers: headers,
        });
    }

    async getChipModalService() {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETCHIPMODALAPI}`, {
            headers: headers,
        });
    }

    async updateChipModalService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${UPDATECHIPMODALAPI}`, data, {
            headers: headers,
        });
    }

    async postUserBetAPIService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${POSTUSERBETAPI}`, data, {
            headers: headers,
        });
    }

    async GetNewsService() {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.get(`${GETNEWSAPI}`, {
            headers: headers,
        });
    }

    async GetAllNewsService() {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.get(`${GETALLNEWSAPI}`, {
            headers: headers,
        });
    }

    async postFancyBetAPIService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${POSTFANCYBETAPI}`, data, {
            headers: headers,
        });
    }

    async GetBetModelList(eventId) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETUSERBETMODELAPI}?marketId=${eventId}`, {
            headers: headers,
        });
    }

    async GetFancyPostion(marketId) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETFANCYPOSTIONAPI}?marketId=${marketId}`, {
            headers: headers,
        });
    }

    async GetProfitLossOnRunners(marketId, sportId) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETPROFITLOSSRUNNERAPI}?marketId=${marketId}&sportsId=${sportId}`, {
            headers: headers,
        });
    }
    async getActiveFancyListService(MarketId) {
        let jsonData = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jsonData?.Token}`
        };

        return await axios.get(`${GETACTIVEFANCYAPI}?MarketId=${MarketId}`, {
            headers: headers,
        });
    }
    async getCustomeBMService(MarketId) {
        let jsonData = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jsonData?.Token}`
        };

        return await axios.get(`${GETCUSTOMEBMAPI}?MarketId=${MarketId}`, {
            headers: headers,
        });
    }

    async getFancyCurrentService(data) {
        let jsonData = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jsonData?.Token}`
        };

        return await axios.get(`${GETFANCYCURRENT}?selectionId=${data.selectionId}&marketId=${data.marketId}`, {
            headers: headers,
        });
    }

    async getUserBookService(bookData) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETUSERBOOK}?marketId=${bookData.marketId}&uid=${bookData.uid}`, {
            headers: headers,
        });
    }


    async GetScoreDataService(eventId) {
        let data = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${data?.Token}`
        };

        return await axios.get(`${GETSCOREAPI}/${eventId}`, {
            headers: headers,
        });
    }

    async GetTennisScoreService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.get(`${GETTENNISSCORE}/${data?.sportId}/${data?.eventId}`, {
            headers: headers,
        });
    }

}

const MarketService = new MarketServiceClass()

export default MarketService;


export const getImgBySportId = (sportId) => {
    let ImageData = ''
    if (sportId === '1') {
        ImageData = {
            img: '/Assets/images/soccer.png',
            alt: 'soccer'
        }
    }
    if (sportId === '2') {
        ImageData = {
            img: '/Assets/images/tennis.png',
            alt: 'tennis'
        }
    }
    if (sportId === '4') {
        ImageData = {
            img: '/Assets/images/cricket.png',
            alt: 'cricket'
        }
    }

    return ImageData;
}
import React, { useEffect } from "react";
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import actions from '../../redux/actions';
import Sports from "../../components/sports/sports";
import { startConnection, stopConnection, subscribeToEvent } from "../../services/SignalRService";

const InPlay = () => {


    const Navigate = useNavigate()
    const marketList = useSelector((state) => state.marketSlice.marketList?.Result);
    const BetfareRets = useSelector((state) => state.marketSlice.BetfareRets?.Result);

    useEffect(() => {
        if (!marketList) {
            actions.getMarketList();
        }
        if (marketList && marketList?.length > 0) {
            const marketids = marketList.map(market => market?.MarketId);
            const queryString = marketids.join(',')
            // Initial API call when the component mounts
            actions.getBetfareRets(queryString);
            // Set up an interval to make subsequent API calls every 5 seconds
            const intervalId = setInterval(() => { actions.getBetfareRets(queryString) }, 5000);
            // Cleanup function to clear the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
    }, [marketList])

    const handleMatchSelect = (eventID, sportId, MarketName, SeriesName) => {
        Navigate(`/market/${sportId}/${eventID}`, { state: { seriesName: SeriesName, matchName: MarketName } });
    }

    useEffect(() => {
        startConnection();

        subscribeToEvent('UpdateMarketList', (data) => {
            actions.getMarketList();
        });

        // Don't forget to stop the connection when the component is unmounted
        return () => {
            stopConnection();
        };
    }, []);

    return (
        <NavbarSidebarWrapper>
            <div className="content-wrapper me-0" > {/* main content wrapper */}
                <div className=''>
                    <Tab.Container defaultActiveKey="tab1" id="tab-layout">
                        <Row className="justify-content-center">
                            <Nav variant="tabs" className="custom-nav-tabs2"> {/* Apply the custom tab color */}
                                <Nav.Item>
                                    <Nav.Link eventKey="tab1" className="custom-nav-link2 cricket"><img src="/Assets/images/cricket.png" className="me-1 pl-2" alt="cricket" width="17" height="17" />Cricket</Nav.Link> {/* Apply the custom tab color */}
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab2" className="custom-nav-link2"><img src="/Assets/images/soccer.png" className="me-1 pl-2" alt="soccer" width="17" height="17" />Soccer</Nav.Link> {/* Apply the custom tab color */}
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab3" className="custom-nav-link2"><img src="/Assets/images/tennis.png" className="me-1 pl-2" alt="tennis" width="17" height="17" />Tennis</Nav.Link> {/* Apply the custom tab color */}
                                </Nav.Item>
                            </Nav>
                        </Row>
                        <Row className="justify-content-center">
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="tab1">
                                        <div className="tab-content custom-tab-content"> {/* Apply the custom border color */}
                                            <Sports marketList={marketList} BetfareRets={BetfareRets} handleMatchSelect={handleMatchSelect} sportId='4' marketStatus='1' />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab2">
                                        <div className="tab-content custom-tab-content"> {/* Apply the custom border color */}
                                            <Sports marketList={marketList} BetfareRets={BetfareRets} handleMatchSelect={handleMatchSelect} sportId='1' marketStatus='1' />
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab3">
                                        <div className="tab-content custom-tab-content"> {/* Apply the custom border color */}
                                            <Sports marketList={marketList} BetfareRets={BetfareRets} handleMatchSelect={handleMatchSelect} sportId='2' marketStatus='1' />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>

                </div>
            </div>
        </NavbarSidebarWrapper>
    )
}

export default InPlay;
import { saveUserBalanceDetails } from "../slices/userSlice";
import UserService from "../../services/userService";
import store from "../store";

export function changePasswordAction(data) {
    return new Promise((resolve, rejact) => {
        UserService.changePasswordService(data).then((res) => {
           /// console.log("change password res", res)
            resolve(res)
        }).catch((error) => {
            console.log("Error getting form change password", error)
            rejact(error)
        })
    })
}
export function GetUserBalanceAction() {
    return new Promise((resolve, rejact) => {
        UserService.GetUserBalanceService().then((res) => {
           /// console.log("GetUserBalance res", res)
            store.dispatch(saveUserBalanceDetails(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error getting form GetUserBalance", error)
            rejact(error)
        })
    })
}

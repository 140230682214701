import { saveAccountStatement, saveProfitAndLoss, saveBetHistory, saveAccountBetHistory, saveMatchBet } from "../slices/reportSlice";
import ReportService from "../../services/reportService";
import store from "../store";

export function getAccountStatement(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountStatementService(data).then((res) => {
            console.log("account statement res", res)
            store.dispatch(saveAccountStatement(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error getting form get account statement", error)
            rejact(error)
        })
    })
}
export function getMatchBet(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getMatchBetService(data).then((res) => {
            console.log("match bet res", res)
            store.dispatch(saveMatchBet(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error getting form get match bet", error)
            rejact(error)
        })
    })
}
export function getAccountBetHistory(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountBetHistoryService(data).then((res) => {
            console.log("AccountBetHistory res", res)
            store.dispatch(saveAccountBetHistory(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error getting form get AccountBetHistory", error)
            rejact(error)
        })
    })
}
export function getAccountFancyBetHistory(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountFancyBetHistoryService(data).then((res) => {
            console.log("AccountFancyBetHistory res", res)
            store.dispatch(saveAccountBetHistory(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error getting form get AccountFancyBetHistory", error)
            rejact(error)
        })
    })
}
export function getProfitAndLoss(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getProfitAndLossService(data).then((res) => {
            console.log("ProfitAndLoss res", res)
            store.dispatch(saveProfitAndLoss(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error getting form getProfitAndLoss", error)
            rejact(error)
        })
    })
}
export function getBetHistory(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getBetHistoryService(data).then((res) => {
            console.log("BetHistory res", res)
            store.dispatch(saveBetHistory(res.data))
            resolve(res)
        }).catch((error) => {
            console.log("Error getting form BetHistory", error)
            rejact(error)
        })
    })
}

import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

const BannerSlider = () => {
  const imageUrls = [
    "/assets/images/slider1.gif",
    "/assets/images/slider2.gif",
    "/assets/images/slider3.gif",
    "/assets/images/slider4.gif",
  ];

  const options = {
    loop: true,
    margin: 20,
    dots: false,
    nav: true,
    center: true,
    stagePadding: 120,
    responsive: {
      0: {
        items: 1,
        margin: 10,

      },
      600: {
        items: 2,
      },
    },
    autoplay: true,
    autoplayTimeout: 3000,
  };

  return (
    <OwlCarousel className="owl-theme" {...options} style={{ zIndex: '1' }}>
      {imageUrls.map((imageUrl, index) => (
        <div key={index} className="item">
          <img src={imageUrl} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </OwlCarousel>
  );
};

export default BannerSlider;
